import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const createHeaders = (token) => {
  const headers = {
    "Content-Type": "application/json", // Adjust content type as needed
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

const get = async (url, token) => {
  try {
    const response = await axios.get(baseUrl + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error; // Re-throw for handling in the calling component
  }
};

const post = async (url, data, token) => {
  try {
    console.log("POST", baseUrl + url);
    const response = await axios.post(baseUrl + url, data, {
      headers: createHeaders(token),
    });
    console.log("**888888***", response)
    return response.data;
  } catch (error) {
    throw error; // Re-throw for handling in the calling component
  }
};

const postFormData = async (url, data, token) =>{
  // console.log("-----------++++++++++",baseUrl + url, data, token)
  const commonTaken ={
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` }
  }
  return axios.post(`${baseUrl}/${url}`,data,commonTaken)
}

const getParam = async (url, data, token) =>{
  // console.log("-------***********",`${baseUrl}/${url}?${data}`,)
  const commonTaken ={
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  }
  return axios.get(`${baseUrl}/${url}?${data}`,commonTaken)
}

// return axios({
//   method: "get",
//   url: `${baseUrl}?${data}`,
//   headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
// })

export default {
  get,
  post,
  // getBody,
  postFormData,
  getParam
};

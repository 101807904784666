import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Auth/Login";
import Otp from "./pages/Auth/Otp";
import allRoutes from "./utils/constant/Routes";
import Dashbord from "./pages/Dashbord";
import Transaction from "./pages/Transaction";
import Chat from "./pages/Chat";
import Payment from "./pages/Payment";
import Notification from "./pages/Notification/Notification";
import User from "./pages/User";
import Blockuser from "./pages/Blockuser";
import { AuthContext } from "./contexts/AuthContext";
import UserDeposit from "./pages/User/UserDeposit";
import UserWithdrawal from "./pages/User/UserWithdrawal";
import UserDashboard from "./pages/User/UserDashboard";
import UserChat from "./pages/User/Chat";
import io from "socket.io-client";
import SocketContext from "./contexts/SocketContext";
import TransactionDetails from "./pages/Notification/TransactionDetails";
import Profile from "./pages/Profile";

function App() {
  const user_id = localStorage.getItem("user_id");
  const socket = io("https://chat.zebraexch.com/", {
    transports: ["websocket"],
    query: "userId=" + user_id,
  });
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
  });

  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const login = (userData) => {
    setAuth({
      isAuthenticated: true,
      user: userData,
    });
  };

  const logout = () => {
    setAuth({
      isAuthenticated: false,
      user: null,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("user_type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_conv_id");

    return <Navigate to="/" replace />;
  };

  const fetchDataFromSession = async () => {
    try {
      const bearerToken = localStorage.getItem("token");
      const userData = JSON.parse(localStorage.getItem("user_data"));
      if (userData && bearerToken) {
        setAuth({
          isAuthenticated: true,
          user: userData,
        });
      }
    } catch (error) {
      console.log("error in fetching from session", error);
    }
  };

  useEffect(() => {
    fetchDataFromSession();
    return () => {
      fetchDataFromSession();
    };
  }, []);

  const roleBasedDeskTop = () => {
    const roleToken = localStorage.getItem("token");
    const roleBaseUserData = JSON.parse(localStorage.getItem("user_data"));

    if (!roleToken) {
      return <Navigate to={allRoutes.LOGIN} replace />;
    }

    switch (roleBaseUserData?.user_type) {
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
        return <Dashbord />;
      case 3:
        return <UserDashboard />;
      default:
        return <Navigate to={allRoutes.LOGIN} replace />;
    }
  };

  const roleBasedRoute = () => {
    const roleToken = localStorage.getItem("token");
    const roleBaseUserData = JSON.parse(localStorage.getItem("user_data"));

    if (!roleToken || !roleBaseUserData) {
      return allRoutes.HOME;
    }

    switch (roleBaseUserData.type) {
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
        return allRoutes.HOME;
      case 3:
        return allRoutes.USERDASHBOARD;
      default:
        return allRoutes.HOME;
    }
  };

  return (
    <Router>
      <SocketContext.Provider value={socket}>
        <AuthContext.Provider value={{ auth, login, logout }}>
          <Routes>
            <Route
              path={allRoutes.HOME}
              element={
                // token ? <Dashbord /> : <Navigate to={allRoutes.LOGIN} replace />
                roleBasedDeskTop()
              }
            />
            <Route
              path={allRoutes.USERDASHBOARD}
              element={
                token ? (
                  <UserDashboard />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            />
            <Route
              path={allRoutes.USERCHAT}
              element={
                token ? <UserChat /> : <Navigate to={allRoutes.LOGIN} replace />
              }
            />
            <Route path={allRoutes.LOGIN} element={<Login />}></Route>
            <Route path={allRoutes.OTP} element={<Otp />}></Route>
            <Route
              path={allRoutes.TRANSACTION}
              element={
                token ? (
                  <Transaction />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            />
            <Route
              path={allRoutes.CHAT}
              element={
                token ? <Chat /> : <Navigate to={allRoutes.LOGIN} replace />
              }
            ></Route>
            <Route
              path={allRoutes.PAYMENT}
              element={
                token ? <Payment /> : <Navigate to={allRoutes.LOGIN} replace />
              }
            ></Route>
            <Route
              path={allRoutes.NOTIFICATION}
              element={
                token ? (
                  <Notification />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path={allRoutes.USER}
              element={
                token ? <User /> : <Navigate to={allRoutes.LOGIN} replace />
              }
            ></Route>
            <Route
              path={allRoutes.BLOCKUSER}
              element={
                token ? (
                  <Blockuser />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path={allRoutes.USERDEPOSITREQUEST}
              element={
                token ? (
                  <UserDeposit />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path={allRoutes.USERWITHDRAWALREQUEST}
              element={
                token ? (
                  <UserWithdrawal />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path={allRoutes.TRANSACTIONDETAILS}
              element={
                token ? (
                  <TransactionDetails />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path={allRoutes.PROFILE}
              element={
                token ? (
                  <Profile />
                ) : (
                  <Navigate to={allRoutes.LOGIN} replace />
                )
              }
            ></Route>
            <Route
              path="*"
              element={<Navigate to={roleBasedRoute()} replace />}
            />
          </Routes>
        </AuthContext.Provider>
      </SocketContext.Provider>
    </Router>
  );
}

export default App;

import React, { useRef, useState, useContext , useEffect } from "react";
import zybraLogo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import UseApi from "../../Api/useApi.js";
import Swal from "sweetalert2";
import messages from "../../utils/constant/constant.js";

function Otp() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const length = 4;
  // const userData = JSON.parse(sessionStorage.getItem('user_data')) || "";
  const userData = JSON.parse(localStorage.getItem("user_data")) || "";

  const inputRef = useRef(Array(length).fill(null));
  const [OTP, setOTP] = useState(Array(length).fill(""));

  const handleTextChange = (input, index) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (index < length - 1 && input !== "") {
      inputRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index !== 0 && OTP[index] === "") {
      inputRef.current[index - 1].focus();
      const newPin = [...OTP];
      newPin[index] = "";
      setOTP(newPin);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      id: userData.id,
      user_type: userData.type,
      otp: OTP.join(""),
    };

    const response = await UseApi.post("/user_otp_verify", body);
    console.log("```````````````",response)
    if (response.code == 200) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
      // login(response.result);
      // localStorage.setItem("token", response.result.token);
      // localStorage.setItem("user_id", response.result.id);
      // localStorage.setItem("user_type", response.result.user_type);
      // localStorage.setItem("user_conv_id", response.result.support_conv_id);
      // localStorage.setItem("customer_care_conv_id",response.result.customer_care_conv_id);
      // localStorage.setItem("support_conv_id",response.result.support_conv_id);
      // navigate("/dashboard");
      window.location.href = 'https://wauser.link/zebraexchofficial';

    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: response.message,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    }
  };

  useEffect(() => {
    document.title = "OTP Page"
  }, []);

  return (
    <div className="main-wrapper">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-6 col-md-7 col-lg-5">
            <div className="login-box verification-box">
              <div className="card border-0 ">
                <div className="card-title text-center ">
                  <div className="logo-box">
                    <Link to="/">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                  </div>
                  <h3>Enter Verification Code</h3>
                  <p>
                    We have sent you a 4 digit verification <br /> code on
                  </p>
                  <p className="number">+91 {userData.phone}</p>
                  <div className="change-nb">
                    <Link to="/login">Change Number</Link>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <p className="otp">Enter OTP</p>
                    <div className="otp-field">
                      {OTP.map((value, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          ref={(el) => (inputRef.current[index] = el)}
                          value={value}
                          onChange={(e) =>
                            handleTextChange(e.target.value, index)
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      ))}
                      {/* <input type="tel" maxLength={1} />
                                            <input type="tel" maxLength={1} />
                                            <input type="tel" maxLength={1} /> */}
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn  main-bg">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;

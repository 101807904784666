import React, { useContext, useEffect, useState } from "react";
import { AuthContext, useAuth } from "../contexts/AuthContext";
import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import axios from "axios";
import { format } from "date-fns";

function Transaction() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [transactions, setTransaction] = useState([]);
  const userToken = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  // const  userToken = localStorage.getItem('token');
  // const  userToken = localStorage.getItem('token');

  console.log(user_data);
  const headers = {
    Authorization: userToken,
  };

  const formatDate = (dateStr) => {
    const newDate = format(dateStr, "yyyy-MM-dd");
    return newDate;
  };

  const formatTime = (dateStr) => {
    const newDate = format(dateStr, "h:mm");
    return newDate;
  };

  console.log(headers);
  const body = {
    id: user_data.id,
    user_type: user_data.user_type,
    filter_user_type: 3,
    narration: "all",
    status: 3,
  };
  useEffect(() => {
    const instance = axios.create({
      baseURL:
        "https://win-champ.showapps.in/api/transaction_list?user_id=" +
        user_data.id +
        "&user_type=" +
        user_data.user_type +
        "&filter_user_type=" +
        3 +
        "&narration=all&status=" +
        1,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user_data.token}`,
      },
    });
    const transaction_list = "";
    const response = instance.get().then((response) => {
      setTransaction(response.data.result);
    });
  }, []);

  // const body1 = axios.get(
  //   `?`,body,headers

  // );
  console.log(transactions);
  return (
    <>
      <Header />
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <div className="main-header-box">
              <a href="#" className="close-menu">
                <i className="fa-solid fa-bars" />
              </a>
              <h5>Transaction List</h5>
              <div className="profile-box">
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/images/profile.png" alt="" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <a className="dropdown-item active" href="profile.html">
                        <i className="fa-regular fa-user" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="login.html">
                        <i className="fa-solid fa-power-off" />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="main-contant">
              <div className="container-fluid">
                <div className="contant-box">
                  <div className="sub-header transaction-sub-header">
                    <div className="row date-box">
                      <div className="col-md-2 col-6">
                        <div className="form-field-wrapper">
                          <div
                            className="input-group date datetimepickerclass pb-0 "
                            id=""
                          >
                            <input
                              className="form-control form-control-lg"
                              type="date"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-6">
                        <div className="form-field-wrapper">
                          <div
                            className="input-group date datetimepickerclass pb-0 "
                            id=""
                          >
                            <input
                              className="form-control form-control-lg"
                              type="date"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-6">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <select className="form-select form-control-lg">
                              <option selected="">Accountant</option>
                              <option value={1}>One</option>
                              <option value={2}>Two</option>
                              <option value={3}>Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-6  ">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <select className="form-select form-control-lg">
                              <option selected="">All</option>
                              <option value={1}>One</option>
                              <option value={2}>Two</option>
                              <option value={3}>Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2  ">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <select className="form-select form-control-lg">
                              <option selected="">Moblie No.</option>
                              <option value={1}>One</option>
                              <option value={2}>Two</option>
                              <option value={3}>Three</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section id="transactionSection" className="mb-0">
                    <div className="row justify-content-center">
                      <div className="col-md-11 col-xl-6">
                        <div className="card-header justify-content-between">
                          <div className="box-heading">
                            <h5>Transaction List</h5>
                          </div>
                          <div className="input-group pb-0 w-auto">
                            <button className="btn-common orange-color">
                              Clear Filter
                            </button>
                          </div>
                        </div>
                        <div className="transaction-list-box" id="scrollbar">
                          {transactions.map((transaction) => (
                            <div className="payment-card">
                              <div className="d-flex align-items-center active">
                                <div className="img-box">
                                  <img
                                    src="./assets/images/withdraw-icon.png"
                                    alt=""
                                  />
                                </div>
                                <div className="user-info text-start">
                                  <h5>{transaction.narration}</h5>
                                  <p>
                                    {" "}
                                    <span className="date">
                                      {formatDate(transaction.date)}
                                    </span>{" "}
                                    |{" "}
                                    <span className="time">
                                      {formatTime(transaction.date)}
                                    </span>
                                  </p>
                                </div>
                                <div className="w-auto ms-auto ">
                                  <button className="btn-common bg-green">
                                    {transaction.amount}
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      s
    </>
  );
}

export default Transaction;

const Routes = {
  HOME: "/",
  HOME_NO_LOGIN: "/logout",
  LOGIN: "/login",
  PROFILE: "/profile",
  OTP: "/otp",
  PAYMENT: "/payment",
  CHAT: "/chat",
  NOTIFICATION: "/notification",
  TRANSACTION: "/transaction",
  USER: "/user",
  BLOCKUSER: "/blockuser",
  DEPOSIT: "/deposit",
  USERDASHBOARD : "/dashboard",
  USERCHAT : "/user/chat",
  USERDEPOSITREQUEST:"/user/userdeposit",
  USERWITHDRAWALREQUEST:'/user/userwithdrawal',
  CREATEGROUP:'/creategroup',
  TRANSACTIONDETAILS:'/transactiondetails'
};

export default Routes;

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext, useAuth } from "../contexts/AuthContext";
import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";

function Blockuser() {
  const userToken = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [blockUser, setBlockUser] = useState([]);

  useEffect(() => {
    const instance = axios.create({
      baseURL:
        "https://win-champ.showapps.in/api/block_user_list?user_id=15" +
        // user_data.id +
        "&user_type=" +
        user_data.user_type,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user_data.token}`,
      },
    });
    const blockuser_list = "";
    const response = instance.get().then((response) => {
      setBlockUser(response.data.result);
    });
  }, []);
  console.log(blockUser);
  return (
    <>
      <Header />
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <div className="main-header-box">
              <a href="#" className="close-menu">
                <i className="fa-solid fa-bars" />
              </a>
              <h5>Block User</h5>
              <div className="profile-box">
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/images/profile.png" alt="" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <a className="dropdown-item active" href="profile.html">
                        <i className="fa-regular fa-user" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="login.html">
                        <i className="fa-solid fa-power-off" />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="main-contant">
              <div className="container-fluid">
                <section className="contant-box" id="blockUserSection">
                  <div className="row justify-content-center">
                    <div className="col-md-11 col-lg-8 col-xl-6">
                      <div
                        className="nav nav-tabs border-0 "
                        id="nav-tab"
                        role="tablist"
                      >
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100 ">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name </h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nav-link w-100">
                          <div className="user-chat-box payment-card">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img src="./assets/images/user.png" alt="" />
                              </div>
                              <div className="user-info">
                                <h6>User Name</h6>
                              </div>
                              <div className="button-box p-0 ms-auto">
                                <ul className="d-flex justify-content-center">
                                  <li className="btn-common bg-black ">
                                    <a href="#">Unblock</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blockuser;

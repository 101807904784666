import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';
import $ from 'jquery';


import App from './App';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);

import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import UseApi from '../../Api/useApi';


function Footer(props) {
    // console.log("Footer", props.from);
    const [count, setCount] = useState('');


    const token = localStorage.getItem('token');
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const userType = JSON.parse(localStorage.getItem("user_type"));

    useEffect(() => {
        const getUnreadCount = async () => {

            const response = await UseApi.get('/unread_count?user_id=' + user_data.id + '&user_type=' + userType, token);

            // console.log("Payment response ", response);
            if (response.code == 200) {
                setCount(response.result);
            }
        }
        return () => {
            getUnreadCount();
        }
    }, []);
    
    // console.log("Payment responsefdxgfd", count);

    return (
        <div className="mobile-navbar">
            <div className="main-navbar">
                <div className="nav">
                    <ul className="nav-bar-nav">
                        <li className="nav-item">
                            {props.from === "userdashbord" ?
                                <Link to="/dashboard" className="nav-link demo active">
                                    <div className="icon-box-outer">
                                        <div className="icon-box">
                                            <svg
                                                width={26}
                                                height={26}
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_16_367)">
                                                    <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                                                    <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                                                    <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                                                    <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_16_367">
                                                        <rect width={26} height={26} fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <span>Dashboard</span>
                                </Link> : <Link to="/dashboard" className="nav-link demo">
                                    <div className="icon-box-outer">
                                        <div className="icon-box position-relative">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_16_367)">
                                                    <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                                                    <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                                                    <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                                                    <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_16_367">
                                                        <rect width={26} height={26} fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <span>Dashboard</span>
                                </Link>
                            }
                        </li>
                        <li className="nav-item">
                            <Link to="/user/chat" className="nav-link demo">
                                <div className="icon-box-outer">
                                    <div className="icon-box position-relative">
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                                        </svg>
                                        <span className=" badge border border-dark   bg-danger rounded-circle">
                                            {count.chat}
                                        </span>
                                    </div>
                                </div>
                                <span>Chat</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            {props.from === "payment" ?
                                <Link to="/payment" className="nav-link demo active">
                                    <div className="icon-box-outer">
                                        <div className="icon-box">
                                            <svg
                                                width={26}
                                                height={26}
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <span>Payment</span>
                                </Link> :
                                <Link to="/payment" className="nav-link demo">
                                    <div className="icon-box-outer">
                                        <div className="icon-box">
                                            <svg
                                                width={26}
                                                height={26}
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <span>Payment</span>
                                </Link>
                            }
                        </li>
                        <li className="nav-item">
                            {props.from === "notification" ?
                                <Link to="/notification" className="nav-link demo active">
                                    <div className="icon-box-outer">
                                        <div className="icon-box">
                                            <svg
                                                width={21}
                                                height={26}
                                                viewBox="0 0 21 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className=""
                                            >
                                                <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                                                <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                                            </svg>
                                            <span className=" badge border border-dark   bg-danger rounded-circle">
                                                {count.notification}
                                            </span>
                                        </div>
                                    </div>
                                    <span>Notification</span>
                                </Link>
                                : <Link to="/notification" className="nav-link demo">
                                    <div className="icon-box-outer">
                                        <div className="icon-box position-relative">
                                            <svg
                                                width={21}
                                                height={26}
                                                viewBox="0 0 21 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className=""
                                            >
                                                <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                                                <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                                            </svg>
                                            <span className=" badge border border-dark   bg-danger rounded-circle">
                                                {count.notification}
                                            </span>
                                        </div>
                                    </div>
                                    <span>Notification</span>
                                </Link>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer;
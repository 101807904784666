import React, { useEffect, useState } from "react";
import Sidebar from "../../components/layout/sidebar";
import Header from "../../components/layout/header";
import UseApi from "../../Api/useApi";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../../Api/useApi";
import uploadImg from "../../assets/images/upload-img.png"
import copyImg from "../../assets/images/copy-icon.png"
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import z_logo from "../../assets/images/z-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/style.css';



const CreateDepositRequest = () => {
  // console.log("*********")
  // const location = useLocation();
  // const { userSites } = location.state;
  // console.log("----------/*-/*-/*-/*-/*-/*-/*-/*-/*-/*-789",userSites)

  const navigate = useNavigate()

  const location = useLocation();
  const userData = location.state;
  // console.log("State", userData)
  const [offerModalOpen, setofferModalOpen] = useState(false);
  const [impoModalOpen, setImpoModalOpen] = useState(false);
  const [existModalOpen, setExistModalOpen] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [offerDetails, setofferDetails] = useState({});
  const [siteList, setSiteList] = useState([]);
  const [userSite, setuserSite] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [upiDetails, setUPIDetails] = useState({});
  const [userListMessage, setUserListMessage] = useState("");
  const [paymentDetailsMessage, setPaymentDetailsMessage] = useState("");
  const [selexch, setSelexch] = useState("");

  const [paymentValue, setPaymentValue] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState("1");
  const [image, setImage] = useState(null);
  const [imageExtension, setImageExtension] = useState("");
  const [offervalue, setOffervalue] = useState("");
  const [offer_id, setOffer_id] = useState("");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [userID, setUserID] = useState("");
  const [offer, setOffer] = useState("");
  const [amount, setAmount] = useState("");
  const [user_name, setUser_name] = useState("");
  const [utr, setUtr] = useState("");

  const bearerToken = localStorage.getItem("token");
  const user_Data = JSON.parse(localStorage.getItem("user_data"));
  const user_conv_id = JSON.parse(localStorage.getItem("user_conv_id"));

  const closeofferModal = () => {
    setofferModalOpen(false);
  };
  const openofferModal = () => {
    setofferModalOpen(true);
  };

  const closeimpoModal = () => {
    setImpoModalOpen(false);
  };
  const openimpoModal = () => {
    setImpoModalOpen(true);
  };

  const closeExistModal = () => {
    setExistModalOpen(false);
  };
  const openExistModal = () => {
    setExistModalOpen(true);
  };

  const closePaymenttModal = () => {
    setPayModalOpen(false);
  };
  const openPaymenttModal = () => {
    setPayModalOpen(true);
  };

  const selectOffer = (id, offerCode) => {
    setOffer_id(id);
    setOffervalue(offerCode);
    setofferModalOpen(false);
  };

  //get offers 
  const getuserSite = async () => {
    try {
      const response = await useApi.get('/active_user_site?user_id=' + user_Data.id + '&user_type=' + user_Data.type, bearerToken);

      if (response.code === 200) {
        setuserSite(response.result);
        console.log("=-=-=", response);
      } else {
        setUserListMessage(response.message);
        openExistModal();
      }
    } catch (error) {

    }
  }

  //get bank & upi data
  const fetchBankAndUpiDetails = async () => {
    try {
      const formData = `user_id=${user_Data.id}&user_type=${user_Data.type}&narration=deposit`
      const response = await useApi.get('/get_active_payment_detail?' + formData, bearerToken);

      if (response.code === 200) {
        setBankDetails(response.result.bank_detail);
        setUPIDetails(response.result.upi_detail);
        // console.log("Bank details" ,upiDetails);
      } else {
        openPaymenttModal();
        setPaymentDetailsMessage(response.message);
      }
    } catch (error) {
      console.log("error in fetching", error);
    }
  };
  useEffect(() => {

    //get offers 
    const getoffers = async () => {
      try {
        const response = await useApi.get('/offer_list?user_id=' + user_Data.id + '&user_type=' + user_Data.type + '&site_id=' + userData.site_id, bearerToken);

        if (response.code === 200) {
          setofferDetails(response.result);
          console.log("=-=-=", response.result);
        }
      } catch (error) {

      }
    }
    const getsiteList = async () => {
      try {
        const response = await useApi.get('/site_list?user_id=' + user_Data.id + '&user_type=' + user_Data.type, bearerToken);

        if (response.code === 200) {
          setSiteList(response.result);
          // console.log("=-=-=", siteList);
        }
      } catch (error) {

      }
    }

    return () => {
      getoffers();
      getsiteList();
    };
  }, []);

  function onChangeValue(event) {
    console.log("///////123456789///////", event.target.value);

    setCheckBoxValue(event.target.value);
  }

  function onChangePaymentDetails(event) {
    console.log(" payment value", event.target.value);
    setPaymentValue(event.target.value);
  }

  const onImageChange = (event) => {
    setFile(event.target.files[0]);
    console.log("image change", file, "image name", file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (paymentValue == "Bank") {
      var payment_ids = bankDetails?.payment_id;
    } else {
      var payment_ids = upiDetails[0]?.payment_id;
    }

    const new_status = checkBoxValue === "1" ? 1 : 0;

    const formData = new FormData();

    formData.append("amount", amount);
    formData.append("user_type", user_Data.type);
    formData.append("user_id", user_Data.id);
    formData.append("payment_id", payment_ids);
    formData.append("narration", "deposit");
    if (checkBoxValue === "1") {
      formData.append("site_id", userData.site_id);
    } else {
      formData.append("site_id", selexch);
    }
    formData.append("site_user_id", userData?.site_user_id);
    formData.append("is_existing", new_status);
    if (checkBoxValue === "1") {
      formData.append("site_username", userData.userid_name);
    } else {
      formData.append("site_username", user_name);
    }
    formData.append("conv_id", user_conv_id);
    formData.append("offer_id", offer_id);
    formData.append("utr_no", utr);
    formData.append("image", file);
    // formData.append('image', fs.createReadStream('/Users/mac/Documents/Nikunj/Projects/Projects/completeProjects/currentProjects/LoveBuzzTango/gift/rar/cupido-bow.png'));
    console.log("*------>file data", file, "file name ->", file.name);

    const response = await useApi.postFormData('create_request', formData, bearerToken);
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    console.log("Submit response", response);

    if (response.data.code === 200) {
      Toast.fire({
        icon: "success",
        title: response.data.message,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
      navigate('/dashboard');
    } else {
      Toast.fire({
        icon: "error",
        title: `${response.data.message}`,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    }
  };

  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <Header title={"DEPOSIT"} />
            <div className="main-contant">
              <div className="container-fluid">
                <div className="contant-box">
                  <section id="userSideDepositSection" className="">
                    <div className="row justify-content-center ">
                      <div className="col-md-11 col-xl-9">
                        <form
                          className="common-form"
                          encType="multipart/form-data"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-group flex-column">
                                <div
                                  className="chekbox radio-box"
                                  onChange={onChangeValue}
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      value="1"
                                      defaultChecked
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      Existing User
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      disabled={userData !== null}
                                      value="0"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault2"
                                    >
                                      New User
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" />

                            <div className="row">
                              {checkBoxValue === "0" ? <>
                                <div className="col-md-6">
                                  <div className="input-group flex-column">
                                    <label htmlFor="" className="form-label">
                                      Desire User Name
                                    </label>
                                    <input type="text" className="form-control" id="" placeholder="" onChange={(e) => setUser_name(e.target.value)} required />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group flex-column ">
                                    <label htmlFor="" className="form-label">
                                      Exchange
                                    </label>
                                    <div className="input-group pb-0 ">
                                      <select className="form-select form-control-lg shadow-none" onChange={(event) => setSelexch(event.target.value)}
                                        required>
                                        <option selected disabled >Enter Exch Name</option>
                                        {siteList.map((site) => (
                                          <option key={site.site_id} value={site.site_id}>
                                            {site.site_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div> </> : null
                              }
                              {checkBoxValue === "1" ? <>
                                <div className="col-md-6">
                                  <div className="input-group flex-column">
                                    <label htmlFor="" className="form-label">
                                      Existing User ID
                                    </label>
                                    <select className="form-select form-control-lg shadow-none" onClick={getuserSite}>
                                      <option selected value={userData?.site_name}>{userData?.site_name}</option>
                                    </select>
                                  </div>
                                </div> </> : null
                              }
                              <div className="col-md-6">
                                <div className="input-group flex-column" onClick={() => { openofferModal() }}>
                                  <label htmlFor="" className="form-label">
                                    Select &amp; Apply Offer
                                  </label>
                                  <select className="form-select form-control-lg shadow-none" placeholder="Select &amp; Apply Offer" onClick={() => { openofferModal() }} >
                                    <option selected value={offervalue} >{offervalue}</option>
                                  </select>
                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-group flex-column">
                                  <label htmlFor="" className="form-label">
                                    Amount
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    placeholder=""
                                    required
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-group flex-column">
                                  <label htmlFor="" className="form-label">
                                    UTR No.
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control utr-no"
                                    id=""
                                    placeholder=""
                                    required
                                    onChange={(e) => setUtr(e.target.value)}
                                  />
                                  <span
                                    className="tooltips-bx"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="This is tooptip"
                                    style={{ "color": "white" }}
                                    onClick={() => { openimpoModal() }}
                                  >
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-group flex-column ">
                                  <label htmlFor="" className="form-label">
                                    Payment Mode
                                  </label>
                                  <div
                                    className="input-group pb-0 "
                                    onChange={onChangePaymentDetails}
                                  >
                                    <select className="form-select form-control-lg shadow-none" onClick={fetchBankAndUpiDetails} >
                                      <option selected value="">Select Deposite Type</option>
                                      <option value="Bank">Bank</option>
                                      <option value="UPI">UPI</option>
                                    </select>
                                  </div>
                                  {(() => {
                                    if (paymentValue == "Bank") {
                                      return (
                                        <div className="input-group flex-column detail-box">
                                          <div className="form-control">
                                            <div className="detail d-flex justify-content-between   ">
                                              <div className="bank-datail col-10">
                                                <div className="col-12 d-flex justify-content-between">
                                                  <p>Bank Name</p>
                                                  <p>{bankDetails?.bank_name}</p>
                                                </div>
                                                <div className="col-12 d-flex justify-content-between ">
                                                  <p>Account Name</p>
                                                  <p>{bankDetails?.holder_name}</p>
                                                </div>
                                                <div className="col-12 d-flex justify-content-between ">
                                                  <p>Account No.</p>
                                                  <p>{bankDetails?.account_no}</p>
                                                </div>
                                                <div className="col-12 d-flex justify-content-between ">
                                                  <p>IFSC Code</p>
                                                  <p>{bankDetails?.ifsc_code}</p>
                                                </div>
                                              </div>
                                              <div className="copy col-1">
                                                <div className="img-box">
                                                  <a href="">
                                                    <img src={copyImg} alt="copy-icon" />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (paymentValue == "UPI") {
                                      return (
                                        <div className="input-group flex-column detail-box">
                                          <div className="form-control">
                                            <div className="detail d-flex justify-content-between   ">
                                              <div className="bank-datail col-10">
                                                {upiDetails[0] != null && (
                                                  <div className="col-12 d-flex justify-content-between">
                                                    {upiDetails.map((x) => {
                                                      return <p>{x.upi_id}</p>;
                                                    })}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-group flex-column">
                                  <label htmlFor="" className="form-label">
                                    Upload image
                                  </label>
                                  <div className="image">
                                    {image ? (
                                      <img
                                        src={image}
                                        alt={`uploaded-img.${imageExtension}`}
                                      />
                                    ) : (
                                      <img
                                        src={uploadImg}
                                        alt="upload-img"
                                      />
                                    )}
                                    <input
                                      type="file"
                                      className="form-control"
                                      id=""
                                      accept="image/*"
                                      required
                                      onChange={onImageChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="button-box">
                                <ul className="d-flex  justify-content-center ">
                                  <li>
                                    <button
                                      className="btn-common orange-color"
                                      type="submit"
                                      data-toggle="modal"
                                      data-target="#myModal-deposite"
                                      id="cust_btn-3"
                                      href="#"
                                    >
                                      {" "}
                                      <span>Submit</span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                     {/* Offer Modal   */}

                    <Modal
                      show={offerModalOpen}
                      onHide={closeofferModal}
                      backdrop="static"
                      // size="lg"
                      centered
                    >
                      <div className="modal-content payment-card payment-card-border">

                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={closeofferModal}
                        ></button>
                        <Modal.Header>
                          <Modal.Title className="text-center w-100" style={{ "color": "white" }}>Select Offers</Modal.Title>
                        </Modal.Header>
                        <Modal.Body id="scrollbar">
                          {offerDetails[0] != null && (
                            <div className="">
                              {offerDetails.map((x, index) => (

                                <div className="offer offer-1" key={index}>
                                  <div className="title-box">
                                    <p>{x.code}</p>
                                  </div>
                                  <div className="offer-content">
                                    <p>{x.title}</p>
                                    <ul>
                                      <li dangerouslySetInnerHTML={{ __html: x.description }}></li>

                                    </ul>
                                    {/* <div className="promo-text">
                                      <span>Promo code is required</span>
                                      <span>This offer is applicable for First time deposit</span>
                                    </div> */}
                                  </div>
                                  <div className="button-box">
                                    <ul className="d-flex justify-content-between">
                                      <li>Offer Percentage:<span className="Percentage">{x.percentage}%</span></li>
                                      <li><button className="btn-common orange-color" onClick={() => { selectOffer(x.offer_id, x.code) }}>Select</button></li>
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                        </Modal.Body>
                      </div>
                    </Modal>

                    {/* Informations modal */}
                    <Modal show={impoModalOpen} onHide={closeimpoModal} centered>
                      <div className="modal-content payment-card payment-card-border">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={closeimpoModal}
                        ></button>
                        <Modal.Header>
                          <Modal.Title className="text-center" style={{ "color": "white" }}>Informations</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                          <div className="text-box text-center">
                            <span>
                              After completing the Transaction Please
                              backfill the UTR No (and Upload screenshot). If you don't
                              backfill it. the deposit Transaction will not Complete.
                              Please be sure to backfill UTR (and Upload proof)
                            </span>
                          </div>
                        </Modal.Body>
                      </div>
                    </Modal>

                    {/* User not existing */}
                    <Modal
                      show={existModalOpen}
                      onHide={closeExistModal}
                      centered
                      className="modal"
                      dialogClassName="modal-dialog-centered modal-md"
                      backdrop="static"
                    >
                      <div className="modal-content payment-card payment-card-border">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={closeExistModal}
                        ></button>
                        <Modal.Header className="btn-close">
                        </Modal.Header>
                        <Modal.Body className="my-3">
                          <div className="logo-box text-center">
                            <img src={z_logo} alt="Logo" />
                          </div>
                          <div className="text-box text-center">
                            <span>
                              {userListMessage}
                            </span>
                          </div>
                          {/* <div className="button-box">
                            <ul className="d-flex justify-content-center">
                              <li>
                                <button className="btn-common orange-color">
                                  <span>Submit</span>
                                </button>
                              </li>
                            </ul>
                          </div> */}
                        </Modal.Body>
                      </div>
                    </Modal>

                    {/* payment modal */}
                    <Modal
                      show={payModalOpen}
                      onHide={closePaymenttModal}
                      centered
                      className="modal"
                      dialogClassName="modal-dialog-centered modal-md"
                      backdrop="static"
                    >
                      <div className="modal-content payment-card payment-card-border">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={closePaymenttModal}
                        ></button>
                        <Modal.Header className="btn-close">
                        </Modal.Header>
                        <Modal.Body className="my-3">
                          <div className="logo-box text-center">
                            <img src={z_logo} alt="Logo" />
                          </div>
                          <div className="text-box text-center">
                            <span>
                              {paymentDetailsMessage}
                            </span>
                          </div>
                          {/* <div className="button-box">
                            <ul className="d-flex justify-content-center">
                              <li>
                                <button className="btn-common orange-color">
                                  <span>Submit</span>
                                </button>
                              </li>
                            </ul>
                          </div> */}
                        </Modal.Body>
                      </div>
                    </Modal>


                    {/* <div className="modal " id="myModal-deposite" role="dialog">
                      <div className="modal-dialog modal-dialog-centered 	modal-md">
                        <div className="modal-content payment-card">
                          <div className="modal-body my-3 ">
                            <div className="logo-box text-center">
                              <img src="./assets/images/z-logo.png" alt="" />
                            </div>
                            <div className="text-box text-center">
                              <span>
                                you don't have any existing user site,
                                <br />
                                please select new user and enter desire userid{" "}
                                <br /> and select side.
                              </span>
                            </div>
                            <div className="button-box">
                              <ul className="d-flex  justify-content-center ">
                                <li>
                                  <button className="btn-common orange-color">
                                    <span>Submit</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateDepositRequest;

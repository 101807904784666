import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../contexts/AuthContext";
import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';
import UseApi from "../Api/useApi.js";
import deposite_1 from '../assets/images/deposite-1.png';
import deposite_2 from '../assets/images/deposite-2.png';
import deposite_3 from '../assets/images/deposite-3.png';
import deposite_4 from '../assets/images/deposite-4.png';
import Swal from "sweetalert2";
import messages from "../utils/constant/constant.js"
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from 'moment';


function Dashbord() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [transactions, setTransaction] = useState([]);
  const userToken = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));



  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-D'))
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-D'))
  const [selectedRole, setSelectedRole] = useState(1);


  const [amountCounts, setAmountCounts] = useState({
    depTotleCount: null,
    depTotleAmount: null,
    depApprovedCount: null,
    depApprovedAmount: null,
    depCancelCount: null,
    depCancelAmount: null,
    depPendingCount: null,
    depPendingAmount: null,
    withTotleCount: null,
    withTotleAmount: null,
    withApprovedCount: null,
    withApprovedAmount: null,
    withCancelCount: null,
    withCancelAmount: null,
    withPendingCount: null,
    withPendingAmount: null,
  });


  const fetchData = async () => {
    try {
      const response = await UseApi.get('/dashboard?user_type=' + user_data.user_type + '&user_id=' + user_data.id + "&filter_user_type=3&start_date=" + startDate + "&end_date=" + endDate, userToken);
      // const response = await instance.get();
      const data = response.result;
      setAmountCounts({
        depTotleCount: data.deposit.total_request.count,
        depTotleAmount: data.deposit.total_request.amount,
        depApprovedCount: data.deposit.approved.count,
        depApprovedAmount: data.deposit.approved.amount,
        depCancelCount: data.deposit.cancel.count,
        depCancelAmount: data.deposit.cancel.amount,
        depPendingCount: data.deposit.pending.count,
        depPendingAmount: data.deposit.pending.amount,
        withTotleCount: data.withdrawal.total_request.count,
        withTotleAmount: data.withdrawal.total_request.amount,
        withApprovedCount: data.withdrawal.approved.count,
        withApprovedAmount: data.withdrawal.approved.amount,
        withCancelCount: data.withdrawal.cancel.count,
        withCancelAmount: data.withdrawal.cancel.amount,
        withPendingCount: data.withdrawal.pending.count,
        withPendingAmount: data.withdrawal.pending.amount,
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    fetchData()
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    fetchData()
  };

  const handleRoleChange = (event) => {
    setSelectedRole(Number(event.target.value));
  };

  useEffect(() => {
    fetchData();
    return () => {
      fetchData();
    }
  }, []);

  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <Header title={"Dashboard"} />
            <div className="main-contant">
              <div className="container-fluid">
                <div className="contant-box">
                  {/* Dashboard sub header */}
                  <div className="sub-header">
                    <div className="row date-box">
                      <div className="col">
                        <div className="form-field-wrapper">
                          <div
                            className="input-group date datetimepickerclass pb-0"
                            id=""
                          >
                            <input
                              className="form-control form-control-lg"
                              type="date"
                              value={startDate}
                              onChange={handleStartDateChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-field-wrapper">
                          <div
                            className="input-group date datetimepickerclass pb-0 "
                            id=""
                          >
                            <input
                              className="form-control form-control-lg"
                              type="date"
                              value={endDate}
                              onChange={handleEndDateChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <select
                              className="form-select form-control-lg"
                              value={selectedRole}
                              onChange={handleRoleChange}
                            >
                              <option value={1}>User</option>
                              <option value={2}>Accountant</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <select className="form-select form-control-lg">
                              {selectedRole === 1 ? (
                                <option value={1}>Mobile No</option>
                              ) : (
                                <option value={2}>Accountant Name</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col last-child ">
                        <div className="form-field-wrapper">
                          <div className="input-group pb-0 ">
                            <input
                              className=" form-control-lg"
                              type="button"
                              defaultValue="Clear Filter"
                              aria-label=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Dashboard Home section */}
                  <section id="dashboard" className="mb-0 ">
                    <div className="dashboard-subbox">
                      <div className="row row-1">
                        <div className="col-md-12">
                          <h5>Deposit</h5>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_1} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Total Request</p>
                                <p className="">{amountCounts.depTotleCount}</p>
                                <p className="number skyblue">₹ {amountCounts.depTotleAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_2} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Accepted</p>
                                <p className="">{amountCounts.withApprovedCount}</p>
                                <p className="number green">₹ {amountCounts.withApprovedAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_3} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Rejected</p>
                                <p className="">{amountCounts.withCancelCount}</p>
                                <p className="number red">₹ {amountCounts.depCancelAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6 ">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_4} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Pending</p>
                                <p className="">{amountCounts.depPendingCount}</p>
                                <p className="number yellow">₹ {amountCounts.depPendingAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="row row-1">
                        <div className="col-md-12">
                          <h5>Withdrawal</h5>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_1} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Total Request</p>
                                <p className="">{amountCounts.withApprovedCount}</p>
                                <p className="number skyblue">₹ {amountCounts.withTotleAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_2} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Accepted</p>
                                <p className="">{amountCounts.withApprovedCount}</p>
                                <p className="number green">₹{amountCounts.withApprovedAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_3} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Rejected</p>
                                <p className="">{amountCounts.withCancelCount}</p>
                                <p className="number red">₹ {amountCounts.withCancelAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 col-xl-3 col-sm-6">
                          <a href="#" className="card ">
                            <div className="card-body card-img">
                              <div className="img-box">
                                <img src={deposite_4} alt="" />
                              </div>
                              <div className="text-box">
                                <p className="card-title">Pending</p>
                                <p className="">{amountCounts.withPendingCount}</p>
                                <p className="number yellow">₹ {amountCounts.withPendingAmount}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashbord;

import React, { useContext, useState, useEffect } from "react";
import Header from "../../components/layout/header.js";
import Sidebar from "../../components/layout/sidebar.js";
import Footer from "../../components/layout/footer.js";
import { Await, Link, useNavigate } from "react-router-dom";
import useApi from "../../Api/useApi.js";
import z_logo from "../../assets/images/z-logo.png";
import ZLogo from "../../assets/images/z-logo-2.png"

import ad_banner from "../../assets/images/ad_banner.png";
import { format } from "date-fns";
import deposit_img from "../../assets/images/deposite-icon.png";

import { AuthContext, useAuth } from "../../contexts/AuthContext.js";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';



function Dashbord() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [userSite, setUserSite] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [sitelistMessage, setSitelistMessage] = useState("");
  const [tranlistMessage, setTranlistMessage] = useState("");
  const navigate = useNavigate()

  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const userToken = localStorage.getItem("token");
  const userType = JSON.parse(localStorage.getItem("user_type"));


  const formatDate = (dateStr) => {
    const newDate = format(dateStr, "yyyy-MM-dd");
    return newDate;
  };

  const formatTime = (dateStr) => {
    const newDate = format(dateStr, "h:mm");
    return newDate;
  };

  //Get site list
  const site_list = async () => {
    const response = await useApi.get(
      "/active_user_site?user_id=" + user_data.id + "&user_type=" + userType
      , userToken);
    if (response.code === 200) {
      setUserSite(response.result);
      // console.log("User list ", response.result);
    } else {
      setSitelistMessage(response.message);
    }
  };

  //Get Transection list
  const transaction_list = async () => {
    const response = await useApi.get(
      "/transaction_list?user_id=" + user_data.id + "&user_type=" + userType + "&filter_user_type=3&narration=all&status=3"
      , userToken);
    if (response.code === 200) {
      setTransactionList(response.result);
    } else {
      setTranlistMessage(response.message)
    }
    // console.log(response.result);
  }

  // get banner Lists
  const bannerLists = async () => {
    const response = await useApi.get(
      "/banner_list?user_id=" + user_data.id + "&user_type=" + userType, userToken);
    if (response.code === 200) {
      // console.log("=-=-", response.result);
      setBannerList(response.result)

    }
  }
  useEffect(() => {
    document.title = "User Dashbord"
    site_list();
    transaction_list();
    bannerLists();
    return () => {
      site_list();
      transaction_list();
      bannerLists();
    };
  }, []);


  // Calling Transaction List api for all data of user


  // navigate('/')

  const handleButtonClickOnDeposit = (userSites) => {
    console.log("--------1234", userSites)
    // navigate('/user/depositrequest', { state: { userSites } });
    const data = userSites;
    navigate('/user/userdeposit', { state: data });
  };
  const handleButtonClickOnWithdrawal = (userSites) => {
    const data = userSites;
    navigate('/user/userwithdrawal', { state: data });
  }

  const handleSubmit = async (requestId, status) => {
    console.log("=======/*-/*-/*-", requestId, status)
    const requestData = {
      requestId, status
    }

    navigate('/transactiondetails', { state: requestData });

  }

  return (
    <>
      <section id="main-box">
        <div className="container-box">

          <Sidebar />
          <div className="right-sidebar">
            <Header title={"Dashboard"} />
            <div className="main-contant">
              <div className="container-fluid">
                <div className="contant-box">
                  {/* <div className="advertisement-img-box">
                    <img src={ad_banner} alt="" className="w-100 " />
                  </div> */}
                  {bannerList !== null && (
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                      {bannerList.map((banner, index) => (
                        <SwiperSlide key={index}>
                          <div className="row justify-content-center">
                            <div className="col-md-11 col-lg-9 col-xxl-7">
                              <div className="advertisement-img-box">
                                <img src={banner.banner_image} alt="img" className="w-100 " />
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}


                  <section
                    id="userTransactionSection"
                    className="mb-0 user-dashborad"
                  >
                    <div className="row justify-content-center">
                      <div className=" col-sm-8 col-md-7 col-xl-6 col-xxl-4">
                        <nav>
                          <div className="nav nav-tabs border-0 justify-content-center" id="nav-tab" role="tablist">
                            <button className="nav-link active col-sm-4 d-flex justify-content-center " id="nav-myID-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-myID" type="button" role="tab" aria-controls="nav-myID"
                              aria-selected="true">My ID’s</button>
                            <button className="nav-link col-sm-4 d-flex justify-content-center" id="nav-Transaction-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-Transaction" type="button" role="tab" aria-controls="nav-Transaction"
                              aria-selected="false">My Transaction</button>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade active show"
                        id="nav-myID"
                        role="tabpanel"
                        aria-labelledby="nav-myID-tab">
                        <div className="row justify-content-center">
                          <div className="col-md-11 col-xl-8 col-xxl-7 ">
                            {userSite !== null && (
                              <div
                                className="transaction-list-box"
                                id="scrollbar"
                              >
                                {userSite.map((userSites, index) => (
                                  <div key={index} className="payment-card">
                                    <div className="d-flex align-items-center active user-detail-box">
                                      {userSites.site_image === null ?
                                        <div className="img-box">
                                          <img src={z_logo} alt="" />
                                        </div> :
                                        <div className="img-box">
                                          <img src={userSites.site_image} alt="" />
                                        </div>}
                                      <div className="user-info text-start">
                                        <h5>
                                          {userSites.site_name}
                                        </h5>
                                        <h5>
                                          {userSites.site_userid_name}
                                        </h5>
                                      </div>
                                      <div className="w-auto ms-auto " >
                                        <h5>
                                          User Name: -{userSites.site_username}
                                        </h5>
                                        <h5>
                                          Password: -{userSites.site_password}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="button-row">
                                      <div className="col">
                                        <button className="btn-common bg-green w-100" onClick={() => handleButtonClickOnDeposit(userSites)}>
                                          <svg
                                            width={27}
                                            height={24}
                                            viewBox="0 0 27 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M21.231 0H5.30775C2.37636 0 0 2.34702 0 5.24222C0 7.44224 1.37216 9.32578 3.31734 10.1033V5.24222C3.31734 4.72086 3.52705 4.22083 3.90032 3.85217C4.27359 3.48351 4.77987 3.27639 5.30775 3.27639H21.231C21.7589 3.27639 22.2652 3.48351 22.6385 3.85217C23.0117 4.22083 23.2214 4.72086 23.2214 5.24222V10.1033C25.1666 9.32578 26.5388 7.44224 26.5388 5.24222C26.5388 2.34702 24.1623 0 21.231 0ZM21.231 20.9689V5.24222H5.30775V20.9689C5.30775 22.4165 6.49593 23.59 7.96162 23.59H18.5771C20.0429 23.59 21.231 22.4165 21.231 20.9689Z"
                                              fill="black"
                                            />
                                            <path
                                              d="M15.222 13.2581L14.267 12.3149L14.267 17.8052C14.267 18.3481 13.8214 18.7881 13.2718 18.7881C12.7222 18.7881 12.2766 18.3481 12.2766 17.8052L12.2766 12.3149L11.3216 13.2581C10.933 13.6419 10.3029 13.6419 9.91427 13.2581C9.52561 12.8742 9.52561 12.2518 9.91427 11.868L11.6299 10.1735C12.5367 9.27791 14.0069 9.27791 14.9138 10.1735L16.6294 11.868C17.0181 12.2518 17.0181 12.8742 16.6294 13.2581C16.2408 13.6419 15.6107 13.6419 15.222 13.2581Z"
                                              fill="#3FE630"
                                            />
                                          </svg>
                                          Deposite
                                        </button>
                                      </div>
                                      <div className="col">
                                        <button className="btn-common bg-red w-100" onClick={() => handleButtonClickOnWithdrawal(userSites)} >
                                          <svg
                                            width={27}
                                            height={24}
                                            viewBox="0 0 27 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M21.231 0H5.30775C2.37636 0 0 2.34702 0 5.24222C0 7.44224 1.37216 9.32578 3.31734 10.1033V5.24222C3.31734 4.72086 3.52705 4.22083 3.90032 3.85217C4.27359 3.48351 4.77987 3.27639 5.30775 3.27639H21.231C21.7589 3.27639 22.2652 3.48351 22.6385 3.85217C23.0117 4.22083 23.2214 4.72086 23.2214 5.24222V10.1033C25.1666 9.32578 26.5388 7.44224 26.5388 5.24222C26.5388 2.34702 24.1623 0 21.231 0ZM21.231 20.9689V5.24222H5.30775V20.9689C5.30775 22.4165 6.49593 23.59 7.96162 23.59H18.5771C20.0429 23.59 21.231 22.4165 21.231 20.9689Z"
                                              fill="black"
                                            />
                                            <path
                                              d="M11.317 15.0315L12.272 15.9747V10.4844C12.272 9.94142 12.7176 9.50146 13.2672 9.50146C13.8169 9.50146 14.2624 9.94142 14.2624 10.4844V15.9747L15.2174 15.0315C15.6061 14.6476 16.2361 14.6476 16.6248 15.0315C17.0134 15.4153 17.0134 16.0377 16.6248 16.4216L14.9092 18.116C14.0024 19.0116 12.5321 19.0116 11.6253 18.116L9.90965 16.4216C9.521 16.0377 9.521 15.4153 9.90965 15.0315C10.2983 14.6476 10.9284 14.6476 11.317 15.0315Z"
                                              fill="#E63030"
                                            />
                                          </svg>
                                          Withdraw
                                        </button>
                                      </div>
                                      <div className="col">
                                        <Link className="btn-common orange-color w-100" to={userSites.site_redirect_url}>
                                          <svg
                                            width={22}
                                            height={24}
                                            viewBox="0 0 22 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M8.5491 1.3107C8.5491 0.58682 7.96292 0 7.23904 0C6.58528 0 5.75035 0 4.8852 0C2.18735 0 0 2.34746 0 5.2428V18.3498C0 21.2451 2.18735 23.5926 4.8852 23.5926H17.0982C19.796 23.5926 21.9834 21.2451 21.9834 18.3498V15.639C21.9834 14.9645 21.4366 14.4177 20.7621 14.4177V14.4177C20.0876 14.4177 19.5408 14.9645 19.5408 15.639V18.3498C19.5408 19.7981 18.4477 20.9712 17.0982 20.9712H4.8852C3.53566 20.9712 2.4426 19.7981 2.4426 18.3498V5.2428C2.4426 3.79448 3.53566 2.6214 4.8852 2.6214H7.2384C7.96227 2.6214 8.5491 2.03458 8.5491 1.3107V1.3107ZM14.7501 2.6214C15.9105 2.6214 16.5244 4.00525 15.7334 4.85421L10.9308 10.0083C10.4787 10.4935 10.4787 11.2457 10.9308 11.7309V11.7309C11.4304 12.2671 12.2799 12.2671 12.7795 11.7309L17.2417 6.94209C18.0636 6.06007 19.5408 6.64162 19.5408 7.8472V7.9536C19.5408 8.6281 20.0876 9.1749 20.7621 9.1749V9.1749C21.4366 9.1749 21.9834 8.6281 21.9834 7.9536V1.3107C21.9834 0.587193 21.4362 0 20.7621 0H14.745C14.0211 0 13.4343 0.58682 13.4343 1.3107V1.3107C13.4343 2.03458 14.0262 2.6214 14.7501 2.6214V2.6214Z"
                                              fill="black"
                                            />
                                          </svg>
                                          Website
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        {sitelistMessage !==  "" ?
                          <div className="empty-data-message">
                            <div className="logo-box text-center">
                              <img src={ZLogo} alt="logo img" />
                            </div>
                            <div className="text-box text-center">
                              <span>
                                {sitelistMessage}
                              </span>
                            </div>
                          </div> : null
                        }

                      </div>
                      {transactionList !== null && (
                        <div className="tab-pane fade "
                          id="nav-Transaction"
                          role="tabpanel"
                          aria-labelledby="nav-Transaction-tab">
                          <div className="row justify-content-center">
                            {transactionList.map((transactionLists, index) => (
                              <div className="row justify-content-center" key={index}>
                                <div className="col-md-11 col-lg-9 col-xxl-7">
                                  <div
                                    className="transaction-list-box"
                                    id="scrollbar"
                                  >
                                    <div className="payment-card">
                                      <div className="d-flex align-items-center active user-detail-box" onClick={() => handleSubmit(transactionLists.request_id, transactionLists.status)}>
                                        <div className="img-box">
                                          <img src={deposit_img} alt="" />
                                        </div>
                                        <div className="user-info text-start">
                                          <h5>{transactionLists.narration}</h5>
                                          <p>
                                            {" "}
                                            <span className="date">
                                              {formatDate(transactionLists.date)}
                                            </span>{" "}
                                            |{" "}
                                            <span className="time">
                                              {formatTime(transactionLists.date)}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="w-auto ms-auto ">
                                          {transactionLists.status === "0" ? (
                                            <button className="btn-common bg-green">
                                              ₹{transactionLists.amount}
                                            </button>
                                          ) : transactionLists.status === "1" ? (
                                            <button className="btn-common bg-yellow">
                                              ₹{transactionLists.amount}
                                            </button>
                                          ) : (
                                            <button className="btn-common bg-red">
                                              ₹{transactionLists.amount}
                                            </button>
                                          )}
                                        </div>
                                      </div>

                                      <div className="button-row">
                                        <div className="img-box">
                                          <img
                                            src={transactionLists.site_image}
                                            alt=""
                                          />
                                        </div>
                                        <div className="user-info text-start">
                                          <h5>
                                            <Link to={transactionLists.site_redirect_url} className="link">
                                              {transactionLists.site_name}
                                              <svg
                                                width={18}
                                                height={20}
                                                viewBox="0 0 22 24"
                                                fill="#fff"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M8.5491 1.3107C8.5491 0.58682 7.96292 0 7.23904 0C6.58528 0 5.75035 0 4.8852 0C2.18735 0 0 2.34746 0 5.2428V18.3498C0 21.2451 2.18735 23.5926 4.8852 23.5926H17.0982C19.796 23.5926 21.9834 21.2451 21.9834 18.3498V15.639C21.9834 14.9645 21.4366 14.4177 20.7621 14.4177V14.4177C20.0876 14.4177 19.5408 14.9645 19.5408 15.639V18.3498C19.5408 19.7981 18.4477 20.9712 17.0982 20.9712H4.8852C3.53566 20.9712 2.4426 19.7981 2.4426 18.3498V5.2428C2.4426 3.79448 3.53566 2.6214 4.8852 2.6214H7.2384C7.96227 2.6214 8.5491 2.03458 8.5491 1.3107V1.3107ZM14.7501 2.6214C15.9105 2.6214 16.5244 4.00525 15.7334 4.85421L10.9308 10.0083C10.4787 10.4935 10.4787 11.2457 10.9308 11.7309V11.7309C11.4304 12.2671 12.2799 12.2671 12.7795 11.7309L17.2417 6.94209C18.0636 6.06007 19.5408 6.64162 19.5408 7.8472V7.9536C19.5408 8.6281 20.0876 9.1749 20.7621 9.1749V9.1749C21.4366 9.1749 21.9834 8.6281 21.9834 7.9536V1.3107C21.9834 0.587193 21.4362 0 20.7621 0H14.745C14.0211 0 13.4343 0.58682 13.4343 1.3107V1.3107C13.4343 2.03458 14.0262 2.6214 14.7501 2.6214V2.6214Z"
                                                  fill="#fff"
                                                />
                                              </svg>
                                            </Link>
                                          </h5>
                                        </div>
                                        <div className="w-auto ms-auto ">
                                          <h5>
                                            {transactionLists.site_username}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {tranlistMessage !==  "" ?
                            <div className="empty-data-message">
                              <div className="logo-box text-center">
                                <img src={ZLogo} alt="logo img" />
                              </div>
                              <div className="text-box text-center">
                                <span>
                                  {tranlistMessage}
                                </span>
                              </div>
                            </div> : null
                          }
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <Footer from={"userdashbord"} />
        </div>
      </section>
    </>
  );
}

export default Dashbord;

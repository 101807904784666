import React, { useContext, useEffect } from 'react';
import $ from 'jquery';
import { AuthContext } from '../../contexts/AuthContext';
import Profiler from '../../assets/images/profile.png';
import supportImg from '../../assets/images/support-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function Header(props) {
    const { logout } = useContext(AuthContext);

    const onClicklogout = () => {
        Swal.fire({
            title: "Logout",
            text: "Are you sure you wont to Logout?",
            icon: "warning",
            showCancelButton: true, // Add a Cancel button
            confirmButtonColor: '#3085d6', // Optional: Set color for OK button (blue)
            cancelButtonColor: '#d33',     // Optional: Set color for Cancel button (red)
        }).then((result) => {
            if (result.isConfirmed) {  // Check if user clicked OK
                logout();
            }
        });
    };
    const userType = localStorage.getItem('user_type');



    useEffect(() => {
        $('.close-menu,.close-btn').click(function () {
            $('.left-sidebar').toggleClass('show-leftbar');
            $('.right-sidebar').toggleClass('show-leftbar');
        });


        // Cleanup to avoid memory leaks
        return () => {
            $('.close-menu,.close-btn').off('click');
        };
    }, []);

    return (
        <>
            <div className="main-header-box">
                {/* {userType == 3 ?
                    <Link to="/dashboard" className="close-menu">
                        <FontAwesomeIcon icon={faBars} />
                    </Link> :
                    <Link to="/" className="close-menu">
                        <FontAwesomeIcon icon={faBars} />
                    </Link>
                } */}
                <h5>{props.title}</h5>
                <div className="profile-box d-flex">
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle"
                            type="button"
                        >
                            <img src={supportImg} alt="" />
                        </button>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <img src={Profiler} alt="" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark">
                            <li>
                                {userType == 3 ?
                                    <Link to="/profile" className="dropdown-item active" >
                                        <FontAwesomeIcon icon={faUser} /> Profile
                                    </Link> :
                                    <Link to="/profile" className="dropdown-item active" >
                                        <FontAwesomeIcon icon={faUser} /> Profile
                                    </Link>
                                }
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <Link className="dropdown-item" onClick={onClicklogout}>
                                    <FontAwesomeIcon icon={faPowerOff} /> Logout
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;

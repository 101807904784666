import React, { useContext, useEffect, useState } from "react";
import { AuthContext, useAuth } from "../contexts/AuthContext";
import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import axios from "axios";
import { format } from "date-fns";
import { Modal } from "react-bootstrap";
import add_icon from "../assets/images/add-icon.png";

function User() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [users, setUser] = useState([]);
  const [userSite, setUserSite] = useState([]);
  const userToken = localStorage.getItem("token");
  const User_data = JSON.parse(localStorage.getItem("User_data"));
  const [mobileNo, setMobileNo] = useState("");
  const [userSiteName, setUserSiteName] = useState("");
  const [exchange, setExchange] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [otherUserType, setOtherUserType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [OtherId, setOtherId] = useState("");
  const [search, setSearch] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
    window.location.reload();
  };

  console.log(User_data);

  const body = {
    user_id: User_data.id,
    user_type: User_data.user_type,
    phone: mobileNo,
    userid_name: userSiteName,
    site_id: exchange,
  };
  console.log(body);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const handleInputChange = (e, phone) => {
    setPhoneNumber(phone);
    setMobileNo(phone);
    setOtherId(e);
    setOtherUserType(e);
    console.log(e);
    axios
      .create({
        baseURL:
          "https://win-champ.showapps.in/api/active_user_site?user_id=" +
          User_data.id +
          "&user_type=" +
          User_data.user_type +
          "&other_user_id=" +
          e,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${User_data.token}`,
        },
      })
      .get()
      .then((response) => {
        console.log(response.data.result);
        setUserSite(response.data.result);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://win-champ.showapps.in/api/create_user_site",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${User_data.token}`,
          },
        }
      );
      console.log("User added successfully:", response.data);
      closeModal(); // Close the modal after successful user addition
    } catch (error) {
      console.error("Error adding user:", error);
      // Handle error
    }
    window.location.reload();
  };

   const handleSearchChange = (e) => {
     setSearch(e.target.value); // Update search state with input value
   };

   useEffect(() => {
     const instance = axios.create({
       baseURL:
         "https://win-champ.showapps.in/api/user_list?filter_user_type=3&search=" +
         search +
         "&page=&size=&user_id=" +
         User_data.id +
         "&user_type=" +
         User_data.user_type,
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${User_data.token}`,
       },
     });
     const transaction_list = "";
     const response = instance.get().then((response) => {
       console.log(response.data.result);
       setUser(response.data.result);
     });
     instance();
   }, [[search]]);

  console.log("phone: " + phoneNumber);

  console.log("User Site Data Type " + userSite);
  return (
    <>
      <Header />
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <div className="main-header-box">
              <a href="#" className="close-menu">
                <i className="fa-solid fa-bars" />
              </a>
              <h5>User</h5>
              <div className="profile-box">
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/images/profile.png" alt="" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <a className="dropdown-item active" href="profile.html">
                        <i className="fa-regular fa-user" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="login.html">
                        <i className="fa-solid fa-power-off" />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="main-contant">
              <div className="container-fluid">
                <section className="contant-box" id="userSection">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="serach-button-tab mt-0 ">
                        <div className="row">
                          <div className="col-lg-9 col-8">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="search"
                                value={search} 
                                onChange={handleSearchChange} 
                              />
                              <i className="fa-solid fa-magnifying-glass" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-4">
                            <button
                              className=" nav-link btn orange-color  mb-0 shadow-none w-100 h-100"
                              type="button"
                              onClick={openModal}
                            >
                              <img src={add_icon} alt="pen" /> Add
                            </button>
                          </div>
                        </div>
                      </div>
                      {users !== null && (
                        <div className="nav nav-tabs mt-4 border-0 " id="nav-tab" role="tablist">
                          {users.map((user) => (
                            <button
                              className="nav-link active w-100 "
                              id="nav-userdetail-1-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-userdetail-1"
                              type="button"
                              role="tab"
                              aria-controls="nav-userdetail-1"
                              aria-selected="true"
                              onClick={() =>
                                handleInputChange(user.user_id, user.phone)
                              }
                            >
                              <div className="user-chat-box payment-card">
                                {/* <input
                                  type="hidden"
                                  className="form-control"
                                  id="otherUserType"
                                  data-id={user.user_id}
                                  placeholder="Enter Mobile No."
                                  onChange={(e) => {
                                    setOtherUserType(e.target.value);
                                  }}
                                /> */}
                                <div className="img-box">
                                  <img src={user.profile_pic} alt="" />
                                </div>
                                <div className="user-info text-start">
                                  <h5>{user.phone}</h5>
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )}
                      </div>
                    <div className="col-md-6">
                      <div className="user-bank-detail mt-4 mt-md-0 ">
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-userdetail-1"
                            role="tabpanel"
                            aria-labelledby="nav-userdetail-1-tab"
                          >
                            <div className="tab-content" id="nav-tabContent">
                              <div className="payment-card">
                                <div className="card-header justify-content-between ">
                                  <div className="user-info ">
                                    <h5>{phoneNumber}</h5>
                                  </div>
                                  <button
                                    className=" nav-link btn orange-color  mb-0 shadow-none"
                                    type="button"
                                    onClick={openModal1}
                                  >
                                    <img src={add_icon} alt="pen" /> Add
                                  </button>
                                </div>
                                <div className="detail mt-2 p-2 ">
                                  {userSite &&
                                    userSite.map((userSites) => (
                                      <div className="col-12 d-flex justify-content-between">
                                        <p>{userSites.site_name}</p>
                                        <p>{userSites.userid_name}</p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-userdetail-2"
                            role="tabpanel"
                            aria-labelledby="nav-userdetail-2-tab"
                          >
                            <div className="tab-content" id="nav-tabContent">
                              <div className="payment-card">
                                <div className="card-header justify-content-between ">
                                  <div className="user-info ">
                                    <h5>+91 85475 87452</h5>
                                  </div>
                                  <button
                                    className=" nav-link btn orange-color  mb-0 shadow-none"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#createUserModal-2"
                                    id="create-user-btn-2"
                                  >
                                    <img
                                      src="./assets/images/add-icon.png"
                                      alt="pen"
                                    />{" "}
                                    Add
                                  </button>
                                </div>
                                <div className="detail mt-2 p-2 ">
                                  <div className="col-12 d-flex justify-content-between">
                                    <p>Exchange</p>
                                    <p>User Name</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-userdetail-3"
                            role="tabpanel"
                            aria-labelledby="nav-userdetail-3-tab"
                          >
                            <div className="tab-content" id="nav-tabContent">
                              <div className="payment-card">
                                <div className="card-header justify-content-between ">
                                  <div className="user-info ">
                                    <h5>+91 85475 87452</h5>
                                  </div>
                                  <button
                                    className=" nav-link btn orange-color  mb-0 shadow-none"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#createUserModal-3"
                                    id="create-user-btn-3"
                                  >
                                    <img
                                      src="./assets/images/add-icon.png"
                                      alt="pen"
                                    />{" "}
                                    Add
                                  </button>
                                </div>
                                <div className="detail mt-2 p-2 ">
                                  <div className="col-12 d-flex justify-content-between">
                                    <p>Exchange</p>
                                    <p>User Name</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-userdetail-4"
                            role="tabpanel"
                            aria-labelledby="nav-userdetail-4-tab"
                          >
                            <div className="tab-content" id="nav-tabContent">
                              <div className="payment-card">
                                <div className="card-header justify-content-between ">
                                  <div className="user-info ">
                                    <h5>+91 85475 87452</h5>
                                  </div>
                                  <button
                                    className=" nav-link btn orange-color  mb-0 shadow-none"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#createUserModal-4"
                                    id="create-user-btn-4"
                                  >
                                    <img
                                      src="./assets/images/add-icon.png"
                                      alt="pen"
                                    />{" "}
                                    Add
                                  </button>
                                </div>
                                <div className="detail mt-2 p-2 ">
                                  <div className="col-12 d-flex justify-content-between">
                                    <p>Exchange</p>
                                    <p>User Name</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-userdetail-5"
                            role="tabpanel"
                            aria-labelledby="nav-userdetail-5-tab"
                          >
                            <div className="tab-content" id="nav-tabContent">
                              <div className="payment-card">
                                <div className="card-header justify-content-between ">
                                  <div className="user-info ">
                                    <h5>+91 85475 87452</h5>
                                  </div>
                                  <button
                                    className=" nav-link btn orange-color  mb-0 shadow-none"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#createUserModal-5"
                                    id="create-user-btn-5"
                                  >
                                    <img
                                      src="./assets/images/add-icon.png"
                                      alt="pen"
                                    />{" "}
                                    Add
                                  </button>
                                </div>
                                <div className="detail mt-2 p-2 ">
                                  <div className="col-12 d-flex justify-content-between">
                                    <p>Exchange</p>
                                    <p>User Name</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                  <div className="col-12 d-flex justify-content-between ">
                                    <p>User Name Site </p>
                                    <p>XYZ User Name - Hash</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Crate user modal  */}
                      <Modal show={isModalOpen} onHide={closeModal} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Create User - Site</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleSubmit}>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                Mobile No
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="mobileNo"
                                value={mobileNo}
                                onChange={(e) => setMobileNo(e.target.value)}
                                placeholder="Enter Mobile No."
                              />
                            </div>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                User Name-Site
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="userSiteName"
                                value={userSiteName}
                                onChange={(e) =>
                                  setUserSiteName(e.target.value)
                                }
                                placeholder="Enter User Name - Site"
                              />
                            </div>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                Exchange
                              </label>
                              <select
                                className="form-select form-control w-100 shadow-none"
                                aria-label=""
                                id="exchange"
                                value={exchange}
                                onChange={(e) => setExchange(e.target.value)}
                              >
                                <option value="" selected>
                                  --Select--
                                </option>
                                <option value="1">XYZ Exch Name 1</option>
                                <option value="2">XYZ Exch Name 2</option>
                                <option value="3">XYZ Exch Name 3</option>
                              </select>
                            </div>
                            <button
                              type="submit"
                              className="btn w-100  orange-color shadow-none"
                            >
                              Submit
                            </button>
                          </form>
                        </Modal.Body>
                      </Modal>

                      <Modal show={isModalOpen1} onHide={closeModal1} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Create User - Site</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleSubmit}>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                Mobile No
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="mobileNo"
                                value={phoneNumber}
                                onChange={(e) => setMobileNo(e.target.value)}
                                readonly="readonly"
                              />
                            </div>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                User Name-Site
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="userSiteName"
                                value={userSiteName}
                                onChange={(e) =>
                                  setUserSiteName(e.target.value)
                                }
                                placeholder="Enter User Name - Site"
                              />
                            </div>
                            <div className="input-group flex-column">
                              <label htmlFor="" className="form-label">
                                Exchange
                              </label>
                              <select
                                className="form-select form-control w-100 shadow-none"
                                aria-label=""
                                id="exchange"
                                value={exchange}
                                onChange={(e) => setExchange(e.target.value)}
                              >
                                <option value="1">XYZ Exch Name 1</option>
                                <option value="2">XYZ Exch Name 2</option>
                                <option value="3">XYZ Exch Name 3</option>
                              </select>
                            </div>
                            <button
                              type="submit"
                              className="btn w-100  orange-color shadow-none"
                            >
                              Submit
                            </button>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default User;

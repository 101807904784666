import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/layout/sidebar'
import Header from '../../components/layout/header'
import UseApi from '../../Api/useApi';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../Api/useApi';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import z_logo from "../../assets/images/z-logo.png";

const CreateWithdrawalRequest = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const getDataFromDashboard = location.state;

    const [checkBoxValue, setCheckBoxValue] = useState("existUser");
    const [existUser, setExistUser] = useState([]);
    const [newUser, setNewUser] = useState([]);
    const [activePayment, setActivePayment] = useState({});
    const [paymentMethod, setPaymentMethod] = useState();
    const [amount, setAmount] = useState("");
    const [userID, setUserID] = useState("");
    const [existUserDropDownValue, setExistUserDropDownValue] = useState("");
    const [bankDetails, setBankDetails] = useState({});
    const [upiDetails, setUPIDetails] = useState({});
    const [paymentId, setPaymentId] = useState('');
    const [payModalOpen, setPayModalOpen] = useState(false);
    const [paymentDetailsMessage, setPaymentDetailsMessage] = useState("");


    const bearerToken = localStorage.getItem("token");
    const userConvId = localStorage.getItem("user_conv_id");
    const userData = JSON.parse(localStorage.getItem("user_data"));

    const onCheckBoxChangeValue = async (event) => {
        setCheckBoxValue(event.target.value);
        checkBoxFunc(event.target.value);
        setAmount("")
        setPaymentMethod(null)

    }
    const onChangePayment = async (event) => {
        console.log(">>>>>>>>/*/*/*/*/*//**", event.target.value)
        setPaymentMethod(event.target.value)
    }
    const onChangeDropDown = async (event) => {
        setExistUserDropDownValue(event.target.value)
    }

    const closePaymenttModal = () => {
        setPayModalOpen(false);
    };
    const openPaymenttModal = () => {
        setPayModalOpen(true);
    };


    const checkBoxFunc = async (checkBoxData) => {

        const userData = JSON.parse(localStorage.getItem("user_data"));
        const userURL = `user_id=${userData.id}&user_type=${userData.type}`

        if (checkBoxData == 'newUser') {

            // const response = await UseApi(
            //     "Get-Param",
            //     "site_list",
            //     userURL,
            //     bearerToken
            // );
            console.log("-------->>>>@@@2")
            const response = await useApi.getParam('active_user_site', userURL, bearerToken);
            console.log("------******------", response)
            if (response.status == 200 && response.data.code == 200) {
                setNewUser(response.data.result)
            }
        } else {

            // const response = await UseApi(
            //     "Get-Param",
            //     "user_site",
            //     userURL,
            //     bearerToken
            // );
            const response = await useApi.getParam('active_user_site', userURL, bearerToken);

            if (response.status == 200 && response.data.code == 200) {
                console.log("----- exist user data 1----", response.data.result)
                setExistUser(response.data.result)
            }
        }
    }

    const fetchExistUserDetails = async () => {
        try {
            const bearerToken = localStorage.getItem("token");
            const userData = JSON.parse(localStorage.getItem("user_data"));
            const userURL = `user_id=${userData.id}&user_type=${userData.type}`

            // const response = await UseApi(
            //     "Get-Param",
            //     "user_site",
            //     userURL,
            //     bearerToken
            // );

            const response = await useApi.getParam('active_user_site', userURL, bearerToken);
            console.log("----- exist user data 2----", response)
            if (response.status == 200 && response.data.code == 200) {
                setExistUser(response.data.result)
                console.log("----- exist user data 2----", response.data.result)
            } else {
                openPaymenttModal();
                setPaymentDetailsMessage(response.data.message);
            }

            const activePaymentURL = `user_id=${userData.id}&user_type=${userData.type}&narration=withdrawal`
            // const activePaymentResponse = await UseApi(
            //     "Get-Param",
            //     "get_active_payment_detail",
            //     activePaymentURL,
            //     bearerToken
            // );
            const activePaymentResponse = await useApi.getParam('get_active_payment_detail', activePaymentURL, bearerToken);

            if (activePaymentResponse.status == 200 && activePaymentResponse.data.code == 200) {
                console.log("##########", activePaymentResponse.data.result)
                setActivePayment(activePaymentResponse.data.result)
                setBankDetails(response.data.result.bank_detail);
                setUPIDetails(response.data.result.upi_detail);
            }

        } catch (error) {
            console.log("error in fetching from session", error);
        }
    };

    const fetchBankAndUpiDetails = async () => {
        try {
            const bearerToken = localStorage.getItem("token");
            const userData = JSON.parse(localStorage.getItem("user_data"));
            // const formData = {
            //     user_id: userData.id,
            //     user_type: userData.type,
            // };

            const userURL = `user_id=${userData.id}&user_type=${userData.type}`

            // const response = await UseApi(
            //     "Get",
            //     "payment_details",
            //     formData,
            //     bearerToken
            // );
            const response = await useApi.getParam('payment_details', userURL, bearerToken);
            console.log("-----****----", response)
            // fetchExistUserDetails()
            if (response.status == 200 && response.data.code == 200) {
                console.log("*******************", response.data.result)

                setBankDetails(response.data.result.bank_detail);
                setUPIDetails(response.data.result.upi_detail);
            } else {
                console.log("??????", response.message)
                openPaymenttModal();
                setPaymentDetailsMessage(response.data.message);
            }
        } catch (error) {
            console.log("error in fetching from session", error);
        }
    };


    useEffect(() => {
        // fetchBankAndUpiDetails();
        // fetchExistUserDetails();
        return () => {
            // fetchBankAndUpiDetails();
            // fetchExistUserDetails();
        };
    }, []);


    const handleSubmit = async (event) => {
        console.log("------------111111111111111111111", existUser, paymentMethod, amount, paymentId)
        console.log("--------________________", existUserDropDownValue)
        event.preventDefault();
        const bearerToken = localStorage.getItem("token");
        const userConvId = localStorage.getItem("user_conv_id");
        const userData = JSON.parse(localStorage.getItem("user_data"));
        var paymentIds
        if(paymentMethod == "UPI"){
             paymentIds = upiDetails[0].payment_id
        }else{
            paymentIds = bankDetails.payment_id
        }
        
        const newFormData = new FormData();
        newFormData.append("amount", amount);
        newFormData.append("user_type", userData.type);
        newFormData.append("user_id", userData.id);
        newFormData.append("payment_id", paymentIds);
        newFormData.append("narration", "withdrawal");
        newFormData.append("site_user_id", existUser[0].site_user_id);
        newFormData.append("is_existing", 1);
        newFormData.append("site_username", existUser[0].site_username);
        newFormData.append("conv_id", userConvId);

        const response = await useApi.postFormData('create_request', newFormData, bearerToken);
        console.log("%%%%%%%%%%%%%", response)
        // if (response.status == 200 && response.data.code == 200) {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        console.log("Submit response", response);

        if (response.data.code === 200) {
            Toast.fire({
                icon: "success",
                title: response.data.message,
                customClass: {
                    container: 'custom-toast' // Apply the class to the container
                }
            });
            navigate('/dashboard');
        } else {
            Toast.fire({
                icon: "error",
                title: `${response.data.message}`,
                customClass: {
                    container: 'custom-toast' // Apply the class to the container
                }
            });
        }
        // }
    }


    const submitPaymentId = async (paymentId) => {
        console.log("***********",paymentId)
        setPaymentId(paymentId)
    }

    return (
        <>
            <section id="main-box">
                <div className="container-box">
                    <Sidebar />
                    <div className="right-sidebar">
                        <Header title={"WITHDRAWAL"} />
                        <div className="main-contant">
                            <div className="container-fluid">
                                <div className="contant-box">
                                    <section id="userSideWithdrawSection" className="">
                                        <div className="row justify-content-center ">
                                            <div className="col-md-11 col-xl-9">
                                                <form className="common-form" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        {(() => {
                                                            if (checkBoxValue == 'existUser') {
                                                                return <div className="col-md-6">
                                                                    <div className="input-group flex-column ">
                                                                        <label htmlFor="" className="form-label">
                                                                            Existing User ID
                                                                        </label>
                                                                        <div className="input-group pb-0 ">
                                                                            <select className="form-select form-control-lg shadow-none  " onChange={onChangePayment} onClick={fetchExistUserDetails}>
                                                                                <option selected=""></option>
                                                                                {existUser.map(option => (
                                                                                    <option key={option.site_user_id} value={option.site_name}>
                                                                                        {`${option.site_userid_name}-${option.site_name}`}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (checkBoxValue == 'newUser') {

                                                                return (<div >
                                                                    <div className="col-md-6">
                                                                        <div className="input-group flex-column">
                                                                            <label htmlFor="" className="form-label">
                                                                                Desire User ID
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id=""
                                                                                placeholder=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="input-group flex-column ">
                                                                            <label htmlFor="" className="form-label">
                                                                                Exch Name
                                                                            </label>
                                                                            <div className="input-group pb-0 ">
                                                                                <select className="form-select form-control-lg shadow-none" onClick={fetchBankAndUpiDetails}>
                                                                                    <option selected=""></option>
                                                                                    <option>XYZ User ID</option>
                                                                                    {newUser.map(option => (
                                                                                        <option key={option.site_id} value={option.site_name}>
                                                                                            {option.site_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        })()}
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">
                                                                    Amount
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder=""
                                                                    value={amount}
                                                                    onChange={(e) => setAmount(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column ">
                                                                <label htmlFor="" className="form-label">
                                                                    Get Withdrawal on
                                                                </label>
                                                                <div className="input-group pb-0">
                                                                    <select className="form-select form-control-lg shadow-none" onChange={onChangePayment} onClick={fetchBankAndUpiDetails} >
                                                                        <option value="" selected></option>
                                                                        {(() => {
                                                                            const options = [];
                                                                            if (activePayment.bank_detail) {
                                                                                options.push(
                                                                                    <option key={activePayment.bank_detail.payment_id} value="Bank" >
                                                                                        Bank
                                                                                    </option>
                                                                                );
                                                                            }
                                                                            if (activePayment.upi_detail) {
                                                                                options.push(
                                                                                    <option key={activePayment.upi_detail.payment_id} value="UPI" >
                                                                                        UPI
                                                                                    </option>
                                                                                );
                                                                            }
                                                                            return options;
                                                                        })()}
                                                                    </select>
                                                                </div>
                                                                {(() => {
                                                                    if (paymentMethod == "Bank") {
                                                                        console.log("---------**********-----------",bankDetails)
                                                                        return (
                                                                            <div className="input-group flex-column detail-box">
                                                                                <div className="form-control">
                                                                                    <div className="detail d-flex justify-content-between   ">
                                                                                        <div className="bank-datail col-10">
                                                                                            <div className="col-12 d-flex justify-content-between">
                                                                                                <p>Bank Name</p>
                                                                                                <p>{bankDetails?.bank_name}</p>
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>Account Name</p>
                                                                                                <p>{bankDetails?.holder_name}</p>
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>Account No.</p>
                                                                                                <p>{bankDetails?.account_no}</p>
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>IFSC Code</p>
                                                                                                <p>{bankDetails?.ifsc_code}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="copy col-1">
                                                                                            <div className="img-box">
                                                                                                {/* <a href="">
                                                                                                    <img src={copyImg} alt="copy-icon" />
                                                                                                </a> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    } else if (paymentMethod == "UPI") {
                                                                        return (
                                                                            <div className="input-group flex-column detail-box">
                                                                                <div className="form-control">
                                                                                    <div className="detail d-flex justify-content-between   ">
                                                                                        <div className="bank-datail col-10">
                                                                                            {upiDetails[0] != null && (
                                                                                                <div className="col-12 d-flex justify-content-between">
                                                                                                    {upiDetails.map((x, index) => {
                                                                                                        return <p key={index}>{x.upi_id}</p>;
                                                                                                    })}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div className="button-box">
                                                            <ul className="d-flex  justify-content-center ">
                                                                <li>
                                                                    <button className="btn-common orange-color">
                                                                        <span>Submit</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal " id="myModal-deposite" role="dialog">
                                            <div className="modal-dialog modal-dialog-centered  modal-md">
                                                {/* Modal content*/}
                                                <div className="modal-content payment-card">
                                                    <div className="modal-body my-3 ">
                                                        <div className="logo-box text-center">
                                                            <img src="./assets/images/z-logo.png" alt="" />
                                                        </div>
                                                        <div className="text-box text-center">
                                                            <span>
                                                                you don't have any existing user site,
                                                                <br />
                                                                please select new user and enter desire userid <br /> and
                                                                select side.
                                                            </span>
                                                        </div>
                                                        <div className="button-box">
                                                            <ul className="d-flex  justify-content-center ">
                                                                <li>
                                                                    <button className="btn-common orange-color">
                                                                        <span>Submit</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal
                                            show={payModalOpen}
                                            onHide={closePaymenttModal}
                                            centered
                                            className="modal"
                                            dialogClassName="modal-dialog-centered modal-md"
                                            backdrop="static"
                                        >
                                            <div className="modal-content payment-card payment-card-border">
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={closePaymenttModal}
                                                ></button>
                                                <Modal.Header className="btn-close">
                                                </Modal.Header>
                                                <Modal.Body className="my-3">
                                                    <div className="logo-box text-center">
                                                        <img src={z_logo} alt="Logo" />
                                                    </div>
                                                    <div className="text-box text-center">
                                                        <span>
                                                            {paymentDetailsMessage}
                                                        </span>
                                                    </div>
                                                    {/* <div className="button-box">
                                                        <ul className="d-flex justify-content-center">
                                                        <li>
                                                            <button className="btn-common orange-color">
                                                            <span>Submit</span>
                                                            </button>
                                                        </li>
                                                        </ul>
                                                    </div> */}
                                                </Modal.Body>
                                            </div>
                                        </Modal>
                                    </section>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </section >
        </>
    )
}

export default CreateWithdrawalRequest



import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../components/layout/sidebar';
import Header from '../../components/layout/header';
import useApi from '../../Api/useApi';
import uploadImg from '../../assets/images/upload-img.png';
import { Modal } from 'react-bootstrap';

const TransactionDetails = () => {
    const location = useLocation();
    const transactionRequestId = location.state;
    console.log("/*-/*-/*-/*-/*-", transactionRequestId);
    const [result, setResult] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [image, setImage] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [status, setStatus] = useState(null);
    const [existModalOpen, setExistModalOpen] = useState(false);

    const userData = JSON.parse(localStorage.getItem("user_data")) || "";
    const userToken = localStorage.getItem("token");
    const userType = JSON.parse(localStorage.getItem("user_type"));

    const fetchTransactionDetails = async () => {
        try {
            const response = await useApi.get(`/view_transaction_detail?user_id=${userData.id}&user_type=${userType}&request_id=${transactionRequestId.requestId}`, userToken);
            console.log("-------******** --------", response);
            if (response.code === 200 && response.success === true) {
                console.log("456456454564564545456456", response.result);
                setResult(response.result);
                setPaymentType(response.result.payment_detail.upi_id ? "UPI" : "Bank");
                setImage(response.result.image);
                if (response.result.status === "0") {
                    setStatus("Accepted");
                } else if (response.result.status === "1") {
                    setStatus("Pending");
                } else if (response.result.status === "2") {
                    setStatus("Rejected");
                }
            }
        } catch (error) {
            console.log("--*****--", error);
        }
    };

    const closeExistModal = () => {
        setExistModalOpen(false);
    };
    const openModel = () => {
        setExistModalOpen(true);
    };

    useEffect(() => {
        fetchTransactionDetails();
        return () => {
            fetchTransactionDetails();
        };
    }, []);

    return (
        <>
            <section id="main-box">
                <div className="container-box">
                    <Sidebar />
                    <div className="right-sidebar">
                        <Header title={`${result.narration ? result.narration : ""} Details`} />
                        <div className="main-contant">
                            <div className="container-fluid">
                                <div className="contant-box">
                                    <section id="userSideDepositSection" className="">
                                        <div className="row justify-content-center ">
                                            <div className="col-md-11 col-xl-9">
                                                <form className="common-form" encType="multipart/form-data">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Existing User ID</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={result.site_userid_name} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Exchange name</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={result.site_name} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Mobile</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={result.phone} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Amount</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={result.amount} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Type</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={result.narration} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className={`input-group flex-column ${status}`}>
                                                                <label htmlFor="" className="form-label">Status {status}</label>
                                                                <input type="text" className="form-control" id="" placeholder="" value={status} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">UTR No.</label>
                                                                <input type="text" className="form-control utr-no" id="" placeholder="" value={result.utr_no} disabled />
                                                                <span className="tooltips-bx" data-toggle="tooltip" data-placement="bottom" title="This is tooptip">
                                                                    <i className="fa-solid fa-circle-exclamation" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column ">
                                                                <label htmlFor="" className="form-label">Payment Mode</label>
                                                                <div className="input-group pb-0">
                                                                    <select className="form-select form-control-lg shadow-none">
                                                                        <option selected="">{paymentType}</option>
                                                                    </select>
                                                                </div>
                                                                {(() => {
                                                                    if (paymentType === "Bank") {
                                                                        return (
                                                                            <div className="input-group flex-column detail-box">
                                                                                <div className="form-control">
                                                                                    <div className="detail d-flex justify-content-between">
                                                                                        <div className="bank-datail col-10">
                                                                                            <div className="col-12 d-flex justify-content-between">
                                                                                                <p>Bank Name</p>
                                                                                                {/* <p>{bankDetails.bank_name}</p> */}
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>Account Name</p>
                                                                                                {/* <p>{bankDetails.holder_name}</p> */}
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>Account No.</p>
                                                                                                {/* <p>{bankDetails.account_no}</p> */}
                                                                                            </div>
                                                                                            <div className="col-12 d-flex justify-content-between ">
                                                                                                <p>IFSC Code</p>
                                                                                                {/* <p>{bankDetails.ifsc_code}</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="copy col-1">
                                                                                            <div className="img-box">
                                                                                                <a href="">
                                                                                                    <img src="../../assets/images/copy-icon.png" alt="copy-icon" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    } else if (paymentType === "UPI") {
                                                                        return (
                                                                            <div className="input-group flex-column detail-box">
                                                                                <div className="form-control">
                                                                                    <div className="detail d-flex justify-content-between">
                                                                                        <div className="bank-datail col-10">
                                                                                            <div className="col-12 d-flex justify-content-between">
                                                                                                <p>{result.payment_detail.upi_id}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">Upload image</label>
                                                                <div className="image">
                                                                    <button type="button" onClick={openModel}>
                                                                        {image ? (
                                                                            <img src={image} alt="uploaded-img" />
                                                                        ) : (
                                                                            <img src={uploadImg} alt="upload-img" />
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <Modal show={existModalOpen} onHide={closeExistModal} centered>
                                            <Modal.Header closeButton></Modal.Header>
                                            <Modal.Body>
                                                {image && (
                                                    <img src={image} alt="Image" height={500} width={1000} />
                                                )}
                                            </Modal.Body>
                                        </Modal>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TransactionDetails;

import React from 'react'
import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';

function Chat() {
  return (
    <>
    <Header/>
    <section id="main-box">
      <div className="container-box">
        <Sidebar/>
        <div className="right-sidebar">
          <div className="main-header-box">
            <a href="#" className="close-menu">
              <i className="fa-solid fa-bars" />
            </a>
            <h5>Chat</h5>
            <div className="profile-box">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="assets/images/profile.png" alt="" />
                </button>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li>
                    <a className="dropdown-item active" href="profile.html">
                      <i className="fa-regular fa-user" />
                      Profile
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="login.html">
                      <i className="fa-solid fa-power-off" />
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="main-contant">
            <div className="container-fluid">
              <section className="contant-box" id="chatSection">
                <div className="row">
                  <div className="col-md-5 col-sm-5">
                    <div className="chat-box chat-box-left">
                      <nav className="">
                        <div
                          className="nav nav-tabs border-0 "
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active col-sm-4"
                            id="nav-User-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-User"
                            type="button"
                            role="tab"
                            aria-controls="nav-User"
                            aria-selected="true"
                          >
                            User
                          </button>
                          <button
                            className="nav-link col-sm-4"
                            id="nav-Admin-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Admin"
                            type="button"
                            role="tab"
                            aria-controls="nav-Admin"
                            aria-selected="false"
                          >
                            Admin
                          </button>
                          <button
                            className="nav-link col-sm-4"
                            id="nav-Accountant-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Accountant"
                            type="button"
                            role="tab"
                            aria-controls="nav-Accountant"
                            aria-selected="false"
                          >
                            Accountant
                          </button>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        {/* User tab  */}
                        <div
                          className="tab-pane fade active show"
                          id="nav-User"
                          role="tabpanel"
                          aria-labelledby="nav-User-tab"
                        >
                          <div className="serach-button-tab">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="search"
                                    className="form-control nav-link"
                                    id=""
                                    placeholder="Serach"
                                  />
                                  <i className="fa-solid fa-magnifying-glass" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <nav className="user-chat-nav" id="scrollbar">
                            <div
                              className="nav nav-tabs border-0 "
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className="nav-link active"
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name 1</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link uncheck"
                                id="nav-User-2-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-2"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-2"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name 2</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link uncheck"
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name 3</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name </h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name </h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name </h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name </h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-3"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-3"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name </h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                  </a>
                                </div>
                              </button>
                            </div>
                          </nav>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-Admin"
                          role="tabpanel"
                          aria-labelledby="nav-Admin-tab"
                        >
                          <div className="serach-button-tab">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group ">
                                  <input
                                    type="search"
                                    className="form-control nav-link  "
                                    id=""
                                    placeholder="Serach"
                                  />
                                  <i className="fa-solid fa-magnifying-glass " />
                                </div>
                              </div>
                            </div>
                          </div>
                          <nav className="user-chat-nav" id="scrollbar">
                            <div
                              className="nav nav-tabs border-0 "
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className="nav-link active"
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                            </div>
                          </nav>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-Accountant"
                          role="tabpanel"
                          aria-labelledby="nav-Accountant-tab"
                        >
                          <div className="serach-button-tab">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group ">
                                  <input
                                    type="search"
                                    className="form-control nav-link  "
                                    id=""
                                    placeholder="Serach"
                                  />
                                  <i className="fa-solid fa-magnifying-glass " />
                                </div>
                              </div>
                            </div>
                          </div>
                          <nav className="user-chat-nav" id="scrollbar">
                            <div
                              className="nav nav-tabs border-0 "
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className="nav-link active"
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                              <button
                                className="nav-link "
                                id="nav-User-1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-User-1"
                                type="button"
                                role="tab"
                                aria-controls="nav-User-1"
                                aria-selected="true"
                              >
                                <div className="user-chat-box">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center active"
                                  >
                                    <div className="img-box">
                                      <img
                                        src="./assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="user-info text-start">
                                      <h5>User Name</h5>
                                      <p>Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="chat-time ms-auto text-center">
                                      <p className="time">22:13</p>
                                      <span> 1 </span>
                                    </div>
                                  </a>
                                </div>
                              </button>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-7">
                    <div className="chat-box-right">
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade active show"
                          id="nav-User-1"
                          role="tabpanel"
                          aria-labelledby="nav-User-1-tab"
                        >
                          <div className="content">
                            <div className="contact-profile card-header">
                              <img src="./assets/images/user.png" alt="" />
                              <div className="user-info ">
                                <h5>User Name 1</h5>
                              </div>
                              <div className="call-setting-tab">
                                <a href="" className="call">
                                  <i className="fa-solid fa-phone" />
                                </a>
                                <a
                                  href="#"
                                  className="profile-setting-btn dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-dark">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-regular fa-user" />
                                      XYZ
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-solid fa-power-off" /> XYZ
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="card-body msg_card_body"
                              id="scrollbar"
                            >
                              <div className="d-flex justify-content-start">
                                <div className="msg_cotainer">
                                  Hello Andrew! I’m david
                                  <span className="msg_time">6:00 PM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="msg_cotainer_send">
                                  Hello david
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-1.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-start ">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-2.png"
                                    alt=""
                                  />
                                  <span className="msg_time">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-3.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                            </div>
                            <div className="box-footer">
                              <form action="#" method="post">
                                <div className="input-group pb-0 ">
                                  <div className="input-field">
                                    <input
                                      className="test"
                                      placeholder="Type Message..."
                                    />
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/link-doc.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/send.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                  </div>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100  m-0 "
                                    >
                                      Deposit
                                    </button>
                                  </span>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100 m-0   "
                                    >
                                      Withdrawal
                                    </button>
                                  </span>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-User-2"
                          role="tabpanel"
                          aria-labelledby="nav-User-2-tab"
                        >
                          <div className="content">
                            <div className="contact-profile card-header">
                              <img src="./assets/images/user.png" alt="" />
                              <div className="user-info ">
                                <h5>User Name 2</h5>
                              </div>
                              <div className="call-setting-tab">
                                <a href="" className="call">
                                  <i className="fa-solid fa-phone" />
                                </a>
                                <a
                                  href="#"
                                  className="profile-setting-btn dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-dark">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-regular fa-user" />
                                      XYZ
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-solid fa-power-off" /> XYZ
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="card-body msg_card_body"
                              id="scrollbar"
                            >
                              <div className="d-flex justify-content-start">
                                <div className="msg_cotainer">
                                  Hello Andrew! I’m david
                                  <span className="msg_time">6:00 PM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="msg_cotainer_send">
                                  Hello david
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-1.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-start ">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-2.png"
                                    alt=""
                                  />
                                  <span className="msg_time">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-3.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                            </div>
                            <div className="box-footer">
                              <form action="#" method="post">
                                <div className="input-group pb-0">
                                  <div className="input-field">
                                    <input
                                      className="test"
                                      placeholder="Type Message..."
                                    />
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/link-doc.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/send.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                  </div>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100  m-0 "
                                    >
                                      Deposit
                                    </button>
                                  </span>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100 m-0   "
                                    >
                                      Withdrawal
                                    </button>
                                  </span>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-User-3"
                          role="tabpanel"
                          aria-labelledby="nav-User-3-tab"
                        >
                          <div className="content">
                            <div className="contact-profile card-header">
                              <img src="./assets/images/user.png" alt="" />
                              <div className="user-info ">
                                <h5>User Name 3</h5>
                              </div>
                              <div className="call-setting-tab">
                                <a href="" className="call">
                                  <i className="fa-solid fa-phone" />
                                </a>
                                <a
                                  href="#"
                                  className="profile-setting-btn dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-dark">
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-regular fa-user" />
                                      XYZ
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <i className="fa-solid fa-power-off" /> XYZ
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="card-body msg_card_body"
                              id="scrollbar"
                            >
                              <div className="d-flex justify-content-start">
                                <div className="msg_cotainer">
                                  Hello Andrew! I’m david
                                  <span className="msg_time">6:00 PM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="msg_cotainer_send">
                                  Hello david
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-1.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-start ">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-2.png"
                                    alt=""
                                  />
                                  <span className="msg_time">8:55 AM</span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="img_cont_msg position-relative ">
                                  <img
                                    src="./assets/images/chat-box-img-3.png"
                                    alt=""
                                  />
                                  <span className="msg_time_send">8:55 AM</span>
                                </div>
                              </div>
                            </div>
                            <div className="box-footer">
                              <form action="#" method="post">
                                <div className="input-group pb-0">
                                  <div className="input-field">
                                    <input
                                      className="test"
                                      placeholder="Type Message..."
                                    />
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/link-doc.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                    <a href="#">
                                      <span>
                                        <img
                                          src="./assets/images/send.png"
                                          alt=""
                                        />
                                      </span>
                                    </a>
                                  </div>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100  m-0 "
                                    >
                                      Deposit
                                    </button>
                                  </span>
                                  <span className="input-group-btn">
                                    <button
                                      type="button"
                                      className="nav-link btn orange-color w-100 m-0   "
                                    >
                                      Withdrawal
                                    </button>
                                  </span>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Modal for chat try  */}
                  <div className="modal " id="createUserModal-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                      {/* Modal content*/}
                      <div className="modal-content payment-card p-0 ">
                        <div className="content">
                          <div className="contact-profile card-header">
                            <img src="./assets/images/user.png" alt="" />
                            <div className="user-info ">
                              <h5>User Name 2</h5>
                            </div>
                            <div className="call-setting-tab">
                              <a href="" className="call">
                                <i className="fa-solid fa-phone" />
                              </a>
                              <a
                                href="#"
                                className="profile-setting-btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                <i className="fa-solid fa-ellipsis-vertical" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="fa-regular fa-user" />
                                    XYZ
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="fa-solid fa-power-off" /> XYZ
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="card-body msg_card_body" id="scrollbar">
                            <div className="d-flex justify-content-start">
                              <div className="msg_cotainer">
                                Hello Andrew! I’m david
                                <span className="msg_time">6:00 PM</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="msg_cotainer_send">
                                Hello david
                                <span className="msg_time_send">8:55 AM</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="img_cont_msg position-relative ">
                                <img
                                  src="./assets/images/chat-box-img-1.png"
                                  alt=""
                                />
                                <span className="msg_time_send">8:55 AM</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-start ">
                              <div className="img_cont_msg position-relative ">
                                <img
                                  src="./assets/images/chat-box-img-2.png"
                                  alt=""
                                />
                                <span className="msg_time">8:55 AM</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="img_cont_msg position-relative ">
                                <img
                                  src="./assets/images/chat-box-img-3.png"
                                  alt=""
                                />
                                <span className="msg_time_send">8:55 AM</span>
                              </div>
                            </div>
                          </div>
                          <div className="box-footer">
                            <form action="#" method="post">
                              <div className="input-group pb-0">
                                <div className="input-field">
                                  <input
                                    className="test"
                                    placeholder="Type Message..."
                                  />
                                  <a href="#">
                                    <span>
                                      <img
                                        src="./assets/images/link-doc.png"
                                        alt=""
                                      />
                                    </span>
                                  </a>
                                  <a href="#">
                                    <span>
                                      <img
                                        src="./assets/images/send.png"
                                        alt=""
                                      />
                                    </span>
                                  </a>
                                </div>
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="nav-link btn orange-color w-100  m-0 "
                                  >
                                    Deposit
                                  </button>
                                </span>
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="nav-link btn orange-color w-100 m-0   "
                                  >
                                    Withdrawal
                                  </button>
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Modal for chat try  */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  

  
)
};

export default Chat
import React, { useEffect, useState, useContext } from "react";
import useApi from "../../Api/useApi.js";
import zybraLogo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import messages from "../../utils/constant/constant.js";
import { useNavigate } from "react-router-dom";

function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setpassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState(null);

  // const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { value } = e.target;
    // const formattedValue = value.replace(/\D/g, '');
    setPhoneNumber(value);
    setpassword(/[a-zA-Z]/.test(value));
  };

  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[2-9]{1}[0-9]{9}$/;
    return phoneRegex.test(number);
  };
  const validateUserID = (number) => {
    return number;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validatePhoneNumber(phoneNumber)) {
      fetchData();
    } else if (validateUserID(phoneNumber)) {
      agentLogin();
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "warning",
        title: messages.VALID,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    }
  };

  const fetchData = async () => {
    try {
      const data = {
        phone: phoneNumber,
      };
      const response = await useApi.post('/user_login', data);
      // console.log(">>>>>>>>>>>>>>1",response)
      // console.log("sessionStorage.setItem",response.code);
      if (response.code === 200) {
        login(response.result);
        setLoggedInUserId(response.result);
        // localStorage.setItem(
        //   "loggedInUserId",
        //   JSON.stringify(response.data.result)
        // );
        localStorage.setItem(
          "user_data",
          JSON.stringify(response.result)
        );
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            container: 'custom-toast' // Apply the class to the container
          }
        });
        localStorage.setItem("user_data", JSON.stringify(response.result));
        navigate("/otp");
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "error",
          title: response.message,
          customClass: {
            container: 'custom-toast' // Apply the class to the container
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const agentLogin = async () => {
    try {
      const body = {
        username: phoneNumber,
        password: passwordValue,
      };
      const response = await useApi.post('/login', body);
      console.log(">>>>>>>>>>>>>>2", response);
      if (response.code === 200) {
        login(response.result);
        setLoggedInUserId(response.result);
        localStorage.setItem("user_data", JSON.stringify(response.result));
        localStorage.setItem("user_id", response.result.id);
        localStorage.setItem("user_type", response.result.user_type);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: response.message,
          customClass: {
            container: 'custom-toast' // Apply the class to the container
          }
        });
        // sessionStorage.setItem(
        //   "user_data",
        //   JSON.stringify(response.data.result)
        // );
        login(response.result);
        localStorage.setItem("token", response.result.token);
        // navigate("/");
        // navigate("/deposit");
        // navigate("withdrawal")
        navigate('/')
      } else if (response.code != 200) {
        login(response.result);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "error",
          title: response.message,
          customClass: {
            container: 'custom-toast' // Apply the class to the container
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    document.title = "Login Page"
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 col-md-7 col-lg-5">
              <div className="login-box">
                <div className="card border-0 ">
                  <div className="card-title text-center ">
                    <div className="logo-box">
                      <Link to="/">
                        <img src={zybraLogo} alt="logo" />
                      </Link>
                    </div>
                    <h3>Log in to your account</h3>
                    <p>Welcome back! Please enter your details.</p>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="" className="form-label">
                          User ID/ Mobile No <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  "
                          id=""
                          value={phoneNumber}
                          onChange={handleInputChange}
                          placeholder="User ID/ Mobile No"
                        />
                      </div>
                      {password && (
                        <div className="form-group">
                          <label htmlFor="" className="form-label">
                            Password <span>*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control "
                            id="password"
                            value={passwordValue}
                            onChange={handlePasswordChange}
                            placeholder="Enter password"
                          />
                          <i className="toggle-password fa-solid fa-eye-slash" />
                        </div>
                      )}
                      <button type="submit" className="btn  main-bg">
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

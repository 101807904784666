import React, { useEffect, useState } from 'react'
import Sidebar from '../components/layout/sidebar'
import Header from '../components/layout/header'
import useApi from '../Api/useApi'
import profilePage from "../assets/images/add-proflie-img.png";
import { Row } from 'react-bootstrap';

const Profile = () => {
    const [profile, setProfile] = useState("")
    const [isProfile, setIsProfile] = useState(false)
    const [result, setResult] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const bearerToken = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user_data"));

    const fetchProfileApi = async () => {
        try {
            
            const formData = `user_id=${userData.id}&user_type=${userData.type}`
            const response = await useApi.getParam('get_profile', formData, bearerToken);
            if (response.status == 200 && response.data.code == 200) {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",response.data.result)
                // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!********!!!!!",profilePage)
                setResult(response.data.result)

                setIsProfile(false)
                setProfile(profilePage)
                // if (response.data.result.profile_pic) {
                //     setIsProfile(true)
                //     setProfile(response.data.result.profile_pic)
                // }else{
                //     setIsProfile(false)
                //     setProfile(profilePage)
                // }
                
            }
        } catch (error) {
            console.log("error in fetching from session", error);
        }
    };

    useEffect(() => {
        document.title = "Profile"
        fetchProfileApi()
        return () => {
            fetchProfileApi();
        };
    }, [])

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        uploadFile(event.target.files[0]);
      };
    
      const uploadFile = async (file) => {
        console.log("------------",file)
        const formData = new FormData();
        formData.append('image', file);
        formData.append('user_type', userData.type);
        formData.append('user_id', userData.id);

        const response = await useApi.postFormData('update_profile', formData, bearerToken);
        console.log("-------****",response)

        if(response.status == 200 && response.data.code == 200){
            setProfile(response.data.result.profile_pic)
        }

      };
    
    return (
        <>
            <section id="main-box">
                <div className="container-box">
                    <Sidebar />
                    <div className="right-sidebar">
                        <Header title={"Profile"} />
                        <div className="main-contant">
                            <div className="container-fluid">
                                <div className="contant-box d-flex flex-column justify-content-center">
                                    <section id="profileSection" className="mb-0">
                                        <div className="row justify-content-center">
                                            <div className="col-md-11 col-xl-8 col-xxl-6">
                                                <form className="common-form">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12">
                                                            <div className="input-group flex-column justify-content-center">
                                                                <div className="image">
                                                                    {(()=>{
                                                                        console.log("----------------////",profile)
                                                                        if(isProfile){
                                                                            

                                                                        return <img src={profile} alt="" class="profile-update"></img>
                                                                        }else{
                                                                            return <img
                                                                            src={profile}
                                                                            alt="upload-img"
                                                                        />
                                                                        }
                                                                    
                                                                    })()}
                                                                   
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id=""
                                                                        placeholder=""
                                                                        alt=""
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10 col-sm-9 col-md-8 col-lg-10 col-xxl-11">
                                                            <div className="input-group flex-column">
                                                                <label htmlFor="" className="form-label">
                                                                    Mobile No.
                                                                </label>
                                                                <input
                                                                    type="tel"
                                                                    className="form-control"
                                                                    id=""
                                                                    placeholder=""
                                                                    value={result.phone}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="input-group justify-content-center">
                                                                <label htmlFor="" className="form-label social-links">
                                                                    Social Links
                                                                </label>
                                                            </div>
                                                            <div className="button-box">
                                                                <ul>
                                                                    <li>
                                                                        <a href="https://www.google.com" className="btn-common" target="_blank">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g clipPath="url(#clip0_702_139)">
                                                                                    <path
                                                                                        d="M12.003 0H11.997C5.3805 0 0 5.382 0 12C0 14.625 0.846 17.058 2.2845 19.0335L0.789 23.4915L5.4015 22.017C7.299 23.274 9.5625 24 12.003 24C18.6195 24 24 18.6165 24 12C24 5.3835 18.6195 0 12.003 0ZM18.9855 16.9455C18.696 17.763 17.547 18.441 16.6305 18.639C16.0035 18.7725 15.1845 18.879 12.4275 17.736C8.901 16.275 6.63 12.6915 6.453 12.459C6.2835 12.2265 5.028 10.5615 5.028 8.8395C5.028 7.1175 5.9025 6.279 6.255 5.919C6.5445 5.6235 7.023 5.4885 7.482 5.4885C7.6305 5.4885 7.764 5.496 7.884 5.502C8.2365 5.517 8.4135 5.538 8.646 6.0945C8.9355 6.792 9.6405 8.514 9.7245 8.691C9.81 8.868 9.8955 9.108 9.7755 9.3405C9.663 9.5805 9.564 9.687 9.387 9.891C9.21 10.095 9.042 10.251 8.865 10.47C8.703 10.6605 8.52 10.8645 8.724 11.217C8.928 11.562 9.633 12.7125 10.671 13.6365C12.0105 14.829 13.0965 15.21 13.485 15.372C13.7745 15.492 14.1195 15.4635 14.331 15.2385C14.5995 14.949 14.931 14.469 15.2685 13.9965C15.5085 13.6575 15.8115 13.6155 16.1295 13.7355C16.4535 13.848 18.168 14.6955 18.5205 14.871C18.873 15.048 19.1055 15.132 19.191 15.2805C19.275 15.429 19.275 16.1265 18.9855 16.9455Z"
                                                                                        fill=""
                                                                                    />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_702_139">
                                                                                        <rect width={24} height={24} fill="" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span>Whatsapp</span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                    <a href="https://www.google.com" className="btn-common" target="_blank">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g clipPath="url(#clip0_702_178)">
                                                                                    <path
                                                                                        d="M9.41679 12.522L9.01987 17.8303C9.23379 17.8322 9.44527 17.7871 9.6378 17.6985C9.83033 17.6099 9.99868 17.4802 10.1297 17.3196L12.7925 14.9016L18.3108 18.7455C19.3224 19.2818 20.0449 18.9999 20.3089 17.8614L23.9312 1.73703C24.253 0.316605 23.3898 -0.239849 22.4033 0.107935L1.11429 7.84339C-0.338509 8.37971 -0.317314 9.14849 0.867657 9.49079L6.31082 11.0997L18.9544 3.59127C19.5497 3.22518 20.0912 3.4247 19.6461 3.79811L9.41679 12.522Z"
                                                                                        fill=""
                                                                                    />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_702_178">
                                                                                        <rect width={24} height={19} fill="" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span>Telegram</span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                    <a href="https://www.google.com" className="btn-common" target="_blank">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g clipPath="url(#clip0_702_180)">
                                                                                    <path
                                                                                        d="M18.9764 4.44189C18.8613 4.44189 18.7488 4.47602 18.6531 4.53996C18.5575 4.6039 18.4828 4.69479 18.4388 4.80112C18.3948 4.90745 18.3833 5.02444 18.4057 5.13732C18.4282 5.2502 18.4836 5.35388 18.565 5.43526C18.6463 5.51664 18.75 5.57206 18.8629 5.59451C18.9758 5.61696 19.0928 5.60544 19.1991 5.56139C19.3054 5.51735 19.3963 5.44277 19.4603 5.34708C19.5242 5.25139 19.5584 5.13889 19.5584 5.0238C19.5578 4.86965 19.4963 4.72197 19.3873 4.61296C19.2783 4.50396 19.1306 4.44246 18.9764 4.44189Z"
                                                                                        fill=""
                                                                                    />
                                                                                    <path
                                                                                        d="M11.9986 6.82544C10.9756 6.82544 9.97556 7.12878 9.12496 7.69713C8.27436 8.26548 7.6114 9.0733 7.21991 10.0184C6.82843 10.9636 6.72598 12.0036 6.92556 13.0069C7.12514 14.0103 7.61776 14.9319 8.34114 15.6553C9.06451 16.3787 9.98614 16.8713 10.9895 17.0709C11.9928 17.2704 13.0328 17.168 13.978 16.7765C14.9231 16.3851 15.7309 15.7221 16.2993 14.8715C16.8676 14.0209 17.171 13.0208 17.171 11.9978C17.171 10.626 16.626 9.31042 15.656 8.3404C14.686 7.37038 13.3704 6.82544 11.9986 6.82544Z"
                                                                                        fill=""
                                                                                    />
                                                                                    <path
                                                                                        d="M17.4203 0H6.57974C4.83521 0.00171172 3.16262 0.695482 1.92905 1.92905C0.695482 3.16262 0.00171172 4.83521 0 6.57974L0 17.4203C0.00171172 19.1648 0.695482 20.8374 1.92905 22.0709C3.16262 23.3045 4.83521 23.9983 6.57974 24H17.4203C19.1648 23.9983 20.8374 23.3045 22.0709 22.0709C23.3045 20.8374 23.9983 19.1648 24 17.4203V6.57974C23.9983 4.83521 23.3045 3.16262 22.0709 1.92905C20.8374 0.695482 19.1648 0.00171172 17.4203 0ZM12 18.5841C10.6984 18.5836 9.42616 18.1973 8.34411 17.4739C7.26205 16.7505 6.41877 15.7225 5.92086 14.5199C5.42296 13.3173 5.2928 11.9941 5.54683 10.7176C5.80087 9.441 6.42769 8.26842 7.34805 7.34805C8.26842 6.42769 9.441 5.80087 10.7176 5.54683C11.9941 5.2928 13.3173 5.42296 14.5199 5.92086C15.7225 6.41877 16.7505 7.26205 17.4739 8.34411C18.1973 9.42616 18.5836 10.6984 18.5841 12C18.5823 13.7457 17.8881 15.4194 16.6537 16.6537C15.4194 17.8881 13.7457 18.5823 12 18.5841ZM18.9763 7.02371C18.5807 7.02371 18.1941 6.90641 17.8652 6.68665C17.5363 6.46688 17.2799 6.15453 17.1285 5.78907C16.9772 5.42362 16.9376 5.02149 17.0147 4.63353C17.0919 4.24556 17.2824 3.8892 17.5621 3.60949C17.8418 3.32979 18.1982 3.13931 18.5861 3.06214C18.9741 2.98497 19.3762 3.02457 19.7417 3.17595C20.1071 3.32732 20.4195 3.58367 20.6392 3.91257C20.859 4.24147 20.9763 4.62814 20.9763 5.02371C20.9757 5.55397 20.7648 6.06234 20.3899 6.43729C20.0149 6.81224 19.5066 7.02314 18.9763 7.02371Z"
                                                                                        fill=""
                                                                                    />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_702_180">
                                                                                        <rect width={24} height={24} fill="" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span>Instagram</span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                    <a href="https://www.google.com" className="btn-common" target="_blank">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M23.0645 5.00484C22.1982 3.97501 20.5987 3.55493 17.5441 3.55493H6.45569C3.33112 3.55493 1.70454 4.00211 0.841471 5.09852C0 6.16753 0 7.74262 0 9.9226V14.0776C0 18.3009 0.9984 20.4452 6.45569 20.4452H17.5442C20.1931 20.4452 21.661 20.0745 22.6106 19.1657C23.5845 18.2337 24 16.712 24 14.0776V9.9226C24 7.62363 23.9349 6.03924 23.0645 5.00484ZM15.4081 12.5737L10.3729 15.2053C10.2603 15.2641 10.1372 15.2933 10.0143 15.2933C9.87507 15.2933 9.73618 15.2559 9.61316 15.1814C9.38152 15.041 9.24008 14.79 9.24008 14.5191V9.27289C9.24008 9.00254 9.38113 8.75171 9.61231 8.61127C9.84356 8.47083 10.1312 8.46123 10.3711 8.58587L15.4063 11.2005C15.6625 11.3335 15.8234 11.598 15.8237 11.8865C15.8241 12.1753 15.6639 12.4401 15.4081 12.5737Z"
                                                                                    fill=""
                                                                                />
                                                                            </svg>
                                                                            <span>Youtube</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Profile

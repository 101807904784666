import React, { useContext, useEffect, useState } from "react";
import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import pen from '../assets/images/pen.svg';
import add_icon from '../assets/images/add-icon.png';
import scan_bar from '../assets/images/Scan-bar.png';
import { Modal } from "react-bootstrap";
import UseApi from '../Api/useApi.js';
import Swal from "sweetalert2";
import messages from "../utils/constant/constant.js";
import Footer from "../components/layout/footer.js";





function Payment() {
  const [banckData, setBanckData] = useState({
    name: '',
    bankName: '',
    accountNumber: '',
    confirmAccountNumber: '',
    ifscCode: '',
    BankpaymentId: '',
  });
  const [upiData, setUpiData] = useState([]);
  const [upiId, setUpiId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [qrModalOpen, setqrModalOpen] = useState(false);
  const [upiQr, setUpiQr] = useState('');
  const [upiQr_id, setUpiQr_id] = useState('');

  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const userType = JSON.parse(localStorage.getItem("user_type"));



  const closeqrModal = () => {
    setqrModalOpen(false);
    setUpiQr('');
    setUpiQr_id('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const openModal = (type) => {
    setIsModalOpen(true);
  };

  const openModal1 = (type) => {
    setIsModalOpen1(true);
  };

  const openrqModal = (url, id) => {
    setqrModalOpen(true);
    setUpiQr(url);
    setUpiQr_id(id);
  };



  const isValidIFSCode = (str) => {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const p = new RegExp(regex);
    if (str === null) {
      return false;
    }
    const m = p.test(str);
    return m;
  }

  const isValidUPIID = (str) => {
    const regex = /[a-zA-Z0-9\.\-]{2,256}\@[a-zA-Z][a-zA-Z]{2,64}/;
    const p = new RegExp(regex);
    if (str === null) {
      return false;
    }
    const m = p.test(str);
    return m;
  }

  const isValidAccNo = (str) => {
    const regex = /[0-9]{8,18}/;
    const p = new RegExp(regex);
    if (str === null) {
      return false;
    }
    const m = p.test(str);
    return m;
  }

  const handleChange = (event) => {
    setBanckData({
      ...banckData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChange1 = (event) => {
    setUpiId(event.target.value);
  };

  const getPaymentDetails = async () => {

    const response = await UseApi.get('/payment_details?user_id=' + user_data.id + '&user_type=' + userType, token);

    // console.log("Payment response ", response);
    if (response.code == 200) {
      if (response.result.bank_detail != null) {
        setBanckData({
          name: response.result.bank_detail.holder_name,
          bankName: response.result.bank_detail.bank_name,
          accountNumber: response.result.bank_detail.account_no,
          ifscCode: response.result.bank_detail.ifsc_code,
        })
      }
      if (response.result.upi_detail != null) {
        setUpiData(response.result.upi_detail)
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    if (banckData.accountNumber != banckData.confirmAccountNumber) {
      Toast.fire({
        icon: "warning",
        title: "Account No and confirm Account not same",
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    } else if (!isValidAccNo(banckData.accountNumber)) {
      Toast.fire({
        icon: "warning",
        title: messages.VALID_ACCO,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    } else if (!isValidIFSCode(banckData.ifscCode)) {
      Toast.fire({
        icon: "warning",
        title: messages.VALID_IFSC,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    } else {
      try {
        const body = {
          "user_id": user_data.id,
          "user_type": userType,
          "bank_name": banckData.bankName,
          "account_no": banckData.accountNumber,
          "ifsc_code": banckData.ifscCode,
          "holder_name": banckData.name,
        }

        const response = await UseApi.post('/create_bank_detail', body, token);

        if (response.code == 200) {
          Toast.fire({
            icon: "success",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
          getPaymentDetails();
          closeModal1();
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
        }
        // console.log("response: ", response)
        closeModal();
      } catch (error) {
        console.error("Error adding user:", error);
      }
    }
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    if (!isValidUPIID(upiId)) {
      Toast.fire({
        icon: "warning",
        title: "Please Enter Valid UPIID",
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    } else {
      try {
        const body = {
          "user_id": user_data.id,
          "user_type": userType,
          "upi_id": upiId,
        }

        const response = await UseApi.post('/create_upi', body, token);

        if (response.code == 200) {
          Toast.fire({
            icon: "success",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
          getPaymentDetails();
          closeModal();
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
            customClass: {
              container: 'custom-toast' // Apply the class to the container
            }
          });
        }

        console.log("response: ", response)
        closeModal1();
      } catch (error) {
        console.error("Error adding user:", error);
      }
    }
  };

  const setActiveId = async (payId, upiId) => {
    console.log(11);
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    const body = {
      "payment_id": payId,
      "user_id": user_data.id,
      "user_type": userType,
    }
    const response = await UseApi.post('/active_upi', body, token);

    if (response.code == 200) {
      Toast.fire({
        icon: "success",
        title: response.message,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
      getPaymentDetails();
    } else {
      Toast.fire({
        icon: "error",
        title: response.message,
        customClass: {
          container: 'custom-toast' // Apply the class to the container
        }
      });
    }
  };

  useEffect(() => {
    document.title = "Payment"
    getPaymentDetails();
    return () => {
      getPaymentDetails();
    }
  }, []);

  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <Header title={'Payment'} />
            <div className="main-contant">
              <div className="container-fluid">
                <section className="contant-box" id="paymentSection">
                  <div className="row d-flex flex-column align-items-center">
                    <div className="col-xl-6 col-md-8">
                      <div className="payment-card">
                        <div className="card-header justify-content-between ">
                          <div className="user-info ">
                            <h5>Bank Details</h5>
                          </div>
                          <button
                            className="nav-link btn orange-color  mb-0 shadow-none "
                            type="button"
                            data-toggle="modal"
                            data-target="#myModal-1"
                            id="cust_btn"
                            onClick={openModal1}
                          >
                            <img
                              src={pen}
                              alt="pen"
                              className=""
                            />{" "}
                            Edit
                          </button>
                        </div>
                        <div className="detail mt-3 p-2 ">
                          <div className="col-12 d-flex justify-content-between">
                            <p>Bank Name</p>
                            <p>{banckData.bankName}</p>
                          </div>
                          <div className="col-12 d-flex justify-content-between ">
                            <p>Account Name</p>
                            <p>{banckData.name}</p>
                          </div>
                          <div className="col-12 d-flex justify-content-between ">
                            <p>Account No.</p>
                            <p>{banckData.accountNumber}</p>
                          </div>
                          <div className="col-12 d-flex justify-content-between ">
                            <p>IFSC Code</p>
                            <p>{banckData.ifscCode}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex flex-column  align-items-center ">
                    <div className="col-xl-6 col-md-8">
                      <div className="payment-card">
                        <div className="card-header justify-content-between ">
                          <div className="user-info ">
                            <h5>UPI Details (1500-2000)</h5>
                          </div>
                          <button
                            className=" nav-link btn orange-color  mb-0 shadow-none"
                            type="button"
                            data-toggle="modal"
                            data-target="#myModal-2"
                            id="cust_btn-2"
                            onClick={openModal}
                          >
                            <img
                              src={add_icon}
                              alt="pen"
                              className=""
                            />{" "}
                            Add
                          </button>
                        </div>
                        {upiData != null && (
                          <div className="detail upi-detail mt-3 p-2 ">
                            {upiData.map((upi, index) => (
                              <div key={index} className="col-12 d-flex justify-content-between align-items-center ">
                                <p>{upi.upi_id}</p>
                                <div className="card-btn-box d-flex">
                                  <button
                                    className={(upi.status == 0 ? 'active-black ' : 'active-green ') + 'upi-btn nav-link btn mb-0 shadow-none'}
                                    type="button"
                                    onClick={() => { setActiveId(upi.payment_id, upi.upi_id) }}
                                  >
                                    {upi.status == 0 ? "Deactive" : "Active"}
                                  </button>
                                  <div className="img-box" onClick={() => { openrqModal(upi.upi_qr, upi.upi_id) }}>
                                    <img src={scan_bar} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                {/* Bank detail modal */}
                <div className="modal-dialog modal-dialog-centered">
                  <Modal show={isModalOpen1} onHide={closeModal1} dialogClassName="" centered >
                    <div className="payment-card payment-card-border">
                      <button
                        type="button"

                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModal1}
                      ></button>
                      <div className="modal-header">
                        <h5 className="text-center">Add Bank</h5>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                          <div className="input-group flex-column">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Name"
                              value={banckData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="input-group flex-column">
                            <label htmlFor="bankName" className="form-label">
                              Bank Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="bankName"
                              name="bankName"
                              placeholder="Enter Bank Name"
                              value={banckData.bankName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="input-group flex-column">
                            <label htmlFor="accountNumber" className="form-label">
                              Account Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="accountNumber"
                              name="accountNumber"
                              placeholder="Enter Account Number"
                              value={banckData.accountNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="input-group flex-column">
                            <label htmlFor="confirmAccountNumber" className="form-label">
                              Confirm Account Number
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="confirmAccountNumber"
                              name="confirmAccountNumber"
                              placeholder="Confirm Account Number"
                              value={banckData.confirmAccountNumber}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="input-group flex-column">
                            <label htmlFor="ifscCode" className="form-label">
                              IFSC Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="ifscCode"
                              name="ifscCode"
                              placeholder="Enter IFSC Code"
                              value={banckData.ifscCode}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 orange-color shadow-none"
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                </div>

                {/* UPI Detail Modal  */}
                <div className="modal-dialog modal-dialog-centered">
                  <Modal show={isModalOpen} onHide={closeModal} centered>
                    {/* Modal content */}
                    <div className="modal-content payment-card payment-card-border">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModal}
                      ></button>
                      <div className="modal-header">
                        <h5 className="text-center">Add Bank</h5>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit1}>
                          <div className="input-group flex-column">
                            <label htmlFor="upiId" className="form-label">
                              UPI Id
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="upiId"
                              placeholder="Enter UPI Id"
                              value={upiId}
                              onChange={handleChange1}
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn w-100 orange-color shadow-none"
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                </div>

                {/* Qr Code Modal   */}
                <Modal show={qrModalOpen} onHide={closeqrModal} centered>
                  <div className="modal-content payment-card payment-card-border">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closeqrModal}
                    ></button>
                    <Modal.Header>
                      <Modal.Title className="text-center" style={{ "color": "white" }}>Scan QR Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="img-box text-center">
                        <img src={upiQr} alt="QR code" className="qr-code" />
                      </div>
                      <div className="text-box text-center">
                        <p className="bank-upi-id">{upiQr_id}</p>
                      </div>
                    </Modal.Body>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <Footer from={"payment"}/>
        </div>
      </section>
    </>
  );
}

export default Payment;

import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "../../components/layout/header";
import Sidebar from "../../components/layout/sidebar";
import useApi from "../../Api/useApi.js";
import SocketContext from "../../contexts/SocketContext.js";
import linkdoc from "../../../src/assets/images/link-doc.png";
import sendIcon from "../../../src/assets/images/send.png";
import audioIcon from "../../../src/assets/images/voice-btn.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns-tz";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "react-bootstrap";

function Chat() {
  const socket = useContext(SocketContext);
  console.log("Connected", socket);
  //   console.log(console.log(socket.id));
  const [currentMessage, setCurrentMessage] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const conv_id = localStorage.getItem("support_conv_id");
  const [oldMessage, setOldMessage] = useState([]);
  const [messageId, setMessageId] = useState(0);
  var mediaRecorder = useRef(null);
  const [recording, setRecording] = useState(false);
  const audioChunks = useRef([]);
  var mediaRecorderRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  //0 - Support , 1 - Customer
  const transactionRequestId = location.state;
  const eventData = {
    Authorization: "Bearer " + token,
    user_type: user_data.type,
    user_id: user_data.id,
    conv_id: conv_id,
  };
  socket.emit("read_allmessage", eventData);
  console.log("all");

  const openModal = (media) => {
    console.log(media);
    setSelectedMedia(media);
    setIsModalOpen(true);
  };
  console.log(isModalOpen);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMedia(null);
  };

  console.log("Update", messageId);

  const oldMessageList = async () => {
    
    try {
      const response = await useApi.get(
        "/old_messages_list?user_type=" +
          user_data.type +
          "&user_id=" +
          user_data.id +
          "&conv_id=" +
          conv_id +
          "&message_id=" +
          messageId +
          "&size=10&page=" +
          page,
        token
      );

      if (response && response.result && response.result.length > 0) {
        setLoading(true);
        setOldMessage((prevMessages) => [...response.result, ...prevMessages]);
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false); // No more messages to fetch
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
    setLoading(false);
  };

  const formatTime = (dateStr) => {
    const utcDate = new Date(dateStr);

    const offset = new Date().getTimezoneOffset();

    const localTime = format(
      new Date(utcDate.getTime() - offset * 60000),
      "h:mm a"
    );

    return localTime.toLocaleString();
  };

  const startRecording = (event) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        console.log("audioChunks", audioChunks);
        mediaRecorder.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          const reader = new FileReader();
          const bodyData = new FormData();
          bodyData.append("content_type", 4);
          // const storedImage = localStorage.getItem("uploadedImage");
          console.log(event.target.files);
          bodyData.append("user_type", user_data.type);
          bodyData.append("user_id", user_data.id);
          bodyData.append("conv_id", conv_id);
          bodyData.append("sender_id", userId);
          bodyData.append("file", audioBlob);

          // var object = {};
          // bodyData.forEach((value, key) => (object[key] = value));
          // // var json = JSON.stringify(object);
          // setOldMessage([...oldMessage, object]);
          // console.log("New ", oldMessage);
          try {
            const response = useApi.postFormData(
              "upload_media",
              bodyData,
              token
            );
            const audioURL = URL.createObjectURL(audioBlob);
            const audio = document.createElement("audio");
            audio.src = audioURL;
            audio.controls = true;
            document.body.appendChild(audio);
            audioChunks.current = [];
          } catch (error) {}
        };
        mediaRecorder.start();
        setRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleImageUpload = () => {
    // Trigger file input click
    document.getElementById("fileInput").click();
  };

  const handleImageUploadFile = (event) => {
    setImageLoader(true);
    const bodyData = new FormData();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      // localStorage.setItem("uploadedImage", base64String);
      setImage(base64String);
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    if (event.target.files[0].type == "video/mp4") {
      bodyData.append("content_type", 5);
    } else {
      bodyData.append("content_type", 3);
    }
    // const storedImage = localStorage.getItem("uploadedImage");
    // console.log(storedImage);
    // if (storedImage) {
    //   setImage(storedImage);
    // }
    bodyData.append("user_type", user_data.type);
    bodyData.append("user_id", user_data.id);
    bodyData.append("conv_id", conv_id);
    bodyData.append("sender_id", userId);
    bodyData.append("file", event.target.files[0]);

    // var object = {};
    // bodyData.forEach((value, key) => (object[key] = value));
    // // var json = JSON.stringify(object);
    // setOldMessage([...oldMessage, object]);
    // console.log("New ", oldMessage);
    // try {
    const response = useApi.postFormData("upload_media", bodyData, token);
    // } catch (error) {
    // } finally {
    //   setLoading(false);
    // }
    setImageLoader(false);
  };

  const sendMessage = async () => {
    if (currentMessage !== "") {
      const messageData = {
        Authorization: "Bearer " + token,
        user_type: user_data.type,
        user_id: user_data.id,
        conv_id: conv_id,
        content: currentMessage,
        content_type: 1,
        client_id: new Date().getTime(),
      };

      if (socket) {
        console.log(currentMessage);

        await socket.emit("chat_message", messageData);
        setCurrentMessage("");
        console.log("Message Sent Successfuly");
      }
    }
    //Clear the input field after sending
  };

  const chat_message = (data) => {
    if (data.message_id) {
      const eventData = {
        Authorization: "Bearer " + token,
        user_type: user_data.type,
        user_id: user_data.id,
        conv_id: conv_id,
        message_id: data.message_id,
      };
      socket.emit("read_message", eventData);
      console.log("read");
    }
    console.log();
    setMessageId(data.message_id);
    // localStorage.setItem("uploadedImage", "");
    setLoading(false);
    oldMessageList(data);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      sendMessage();
    }
  };

  const handleButtonClickOnDeposit = () => {
    navigate("/user/userdeposit");
  };
  const handleButtonClickOnWithdrawal = (userSites) => {
    navigate("/user/userwithdrawal");
  };

  useEffect(() => {
    socket.on("chat_message", chat_message);
    // oldMessageList();
    return () => {
      // oldMessageList();
      socket.off("chat_message", chat_message);
    };
    // Check if socket is defined before using it
  }, [socket]);

  useEffect(() => {
    oldMessageList();
    return () => {
      document.title = "User Chat";
      oldMessageList();
    };
  }, []);
  console.log(image);
  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <Header title={"Support"} />
            <Modal show={isModalOpen} onHide={closeModal} centered>
              {/* <Modal.Header closeButton></Modal.Header> */}
              {selectedMedia && (
                <div className="modal-media">
                  {selectedMedia.content_type == 3 && (
                    <img
                      src={selectedMedia.content}
                      alt="Image"
                      height={500}
                      width={1000}
                    />
                  )}
                  {selectedMedia.content_type == 5 && (
                    <video controls height={500} width={1500}>
                      <source src={selectedMedia.content} type="video/mp4" />
                    </video>
                  )}
                </div>
              )}
            </Modal>
            <div className="main-contant">
              <div className="container-fluid">
                <section className="contant-box userChatBox" id="chatSection">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="chat-box-right">
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id=""
                            role="tabpanel"
                            aria-labelledby="nav-User-1-tab"
                          >
                            <div className="content">
                              {loading && (
                                <h4 style={{ textAlign: "center" }}>
                                  Loading......
                                </h4>
                              )}
                              <div
                                className="card-body msg_card_body chat-support"
                                id="scrollbar"
                                style={{
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                }}
                              >
                                <InfiniteScroll
                                  dataLength={oldMessage.length}
                                  next={oldMessageList}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  }} //To put endMessage and loader to the top.
                                  inverse={true} //
                                  hasMore={true}
                                  loader={""}
                                  scrollableTarget="scrollbar"
                                >
                                  {oldMessage !== null &&
                                    oldMessage
                                      .sort(
                                        (a, b) =>
                                          new Date(b.created_at) -
                                          new Date(a.created_at)
                                      )
                                      .map((message, index) =>
                                        message.sender_id !== user_data.id ? (
                                          <div
                                            key={index}
                                            className="d-flex justify-content-start"
                                          >
                                            {message.content_type == 3 && (
                                              <div className="img_cont_msg position-relative ">
                                                <img
                                                  src={message.content}
                                                  alt="Received Image"
                                                  // style={{ height: "300px" }}
                                                  onClick={() =>
                                                    openModal(message)
                                                  }
                                                  style={{
                                                    borderRadius:
                                                      "15px 15px 15px 2px",
                                                    boxShadow: "0 0 0 2px #fff",
                                                    marginLeft: "5px",
                                                  }}
                                                  height={145}
                                                  width={245}
                                                />
                                                <span className="msg_time">
                                                  {message.created_at
                                                    ? formatTime(
                                                        message.created_at
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                            {message.content_type == 4 && (
                                              <div className="img_cont_msg position-relative ">
                                                <audio controls>
                                                  <source
                                                    src={message.content}
                                                    type="audio/mpeg"
                                                  />
                                                  Your browser does not support
                                                  the audio element.
                                                </audio>
                                                <span className="msg_time">
                                                  {formatTime(
                                                    message.created_at
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                            {message.content_type == 5 && (
                                              <div className="img_cont_msg position-relative ">
                                                <img
                                                  src={message.thumbnail}
                                                  alt="Received Image"
                                                  onClick={() =>
                                                    openModal(message)
                                                  }
                                                  style={{
                                                    borderRadius:
                                                      "15px 15px 15px 2px",
                                                    boxShadow: "0 0 0 2px #fff",
                                                    marginLeft: "5px",
                                                  }}
                                                  height={145}
                                                  width={245}
                                                />
                                                <span className="msg_time">
                                                  {formatTime(
                                                    message.created_at
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                            {message.content_type != 3 &&
                                              message.content_type != 4 &&
                                              message.content_type != 5 && (
                                                <div className="msg_cotainer">
                                                  <span>{message.content}</span>
                                                  <span className="msg_time">
                                                    {message.created_at
                                                      ? formatTime(
                                                          message.created_at
                                                        )
                                                      : null}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        ) : (
                                          <div
                                            key={index}
                                            className="d-flex justify-content-end"
                                          >
                                            {message.content_type == 3 && (
                                              <div className="img_cont_msg position-relative ">
                                                <img
                                                  src={
                                                    message.content
                                                      ? message.content
                                                      : image
                                                  }
                                                  alt="Received Image"
                                                  onClick={() =>
                                                    openModal(message)
                                                  }
                                                  style={{
                                                    borderRadius:
                                                      "15px 15px 2px 15px",
                                                    boxShadow: "0 0 0 2px #fff",
                                                    marginRight: "5px",
                                                  }}
                                                  height={145}
                                                  width={245}
                                                />
                                                <span className="msg_time_send">
                                                  {message.created_at
                                                    ? formatTime(
                                                        message.created_at
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                            {message.content_type == 4 && (
                                              <div className="img_cont_msg position-relative ">
                                                <audio controls>
                                                  <source
                                                    src={message.content}
                                                    type="audio/mpeg"
                                                  />
                                                  Your browser does not support
                                                  the audio element.
                                                </audio>
                                              </div>
                                            )}
                                            {message.content_type == 5 && (
                                              <div className="img_cont_msg position-relative ">
                                                <img
                                                  src={message.thumbnail}
                                                  alt="Received Image"
                                                  onClick={() =>
                                                    openModal(message)
                                                  }
                                                  style={{
                                                    borderRadius:
                                                      "15px 15px 2px 15px",
                                                    boxShadow: "0 0 0 2px #fff",
                                                    marginRight: "5px",
                                                  }}
                                                  height={145}
                                                  width={245}
                                                />
                                                <span className="msg_time_send">
                                                  {message.created_at
                                                    ? formatTime(
                                                        message.created_at
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            )}
                                            {message.content_type != 3 &&
                                              message.content_type != 4 &&
                                              message.content_type != 5 && (
                                                <div className="msg_cotainer_send">
                                                  <span>{message.content}</span>
                                                  <span className="msg_time_send">
                                                    {message.created_at
                                                      ? formatTime(
                                                          message.created_at
                                                        )
                                                      : null}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        )
                                      )}
                                </InfiniteScroll>
                              </div>
                              <div className="box-footer">
                                <form action="#" method="post">
                                  <div className="input-group pb-0 ">
                                    <div className="input-field">
                                      <input
                                        className="test"
                                        placeholder="Type Message..."
                                        value={currentMessage}
                                        onChange={(event) => {
                                          setCurrentMessage(event.target.value);
                                        }}
                                        onKeyPress={handleKeyPress}
                                      />

                                      <input
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleImageUploadFile}
                                      />

                                      <div className="chat-control">
                                        <Link
                                          className="voice-bt"
                                          onClick={startRecording}
                                        >
                                          <span>
                                            <img src={audioIcon} alt="" />
                                          </span>
                                        </Link>

                                        <Link
                                          className="link-doc"
                                          onClick={handleImageUpload}
                                        >
                                          <span>
                                            <img src={linkdoc} alt="" />
                                          </span>
                                        </Link>

                                        {/* <button
                                          onClick={stopRecording}
                                          disabled={!recording}
                                        >
                                          Stop Recording
                                        </button> */}
                                        <Link
                                          className="send-btn"
                                          onClick={sendMessage}
                                        >
                                          <span>
                                            <img src={sendIcon} alt="" />
                                          </span>
                                        </Link>
                                      </div>
                                    </div>

                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        className="nav-link btn orange-color w-100  m-0 "
                                        onClick={handleButtonClickOnDeposit}
                                      >
                                        Deposit
                                      </button>
                                    </span>
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        className="nav-link btn orange-color w-100 m-0"
                                        onClick={handleButtonClickOnWithdrawal}
                                      >
                                        Withdrawal
                                      </button>
                                    </span>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chat;

import React, { useRef, useState, useContext, useEffect } from "react";
import UseApi from "../../Api/useApi";
import Header from '../../components/layout/header';
import Sidebar from '../../components/layout/sidebar';
import ZLogo from "../../assets/images/z-logo-2.png"
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../components/layout/footer";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faSolid } from '@fortawesome/free-solid-svg-icons';


function Notification() {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user_data")) || "";
  const userToken = localStorage.getItem("token");
  const userType = JSON.parse(localStorage.getItem("user_type"));


  const [notifications, setNotification] = useState([]);
  const [listMessage, setListMessage] = useState("");
  const [status, setStatus] = useState(null);
  const [paymentType, setPaymentType] = useState("")

  // console.log("userToken :", userToken);
  // console.log("Fetching user data 654:", userData);
  const readNotification = async (notificationId) => {
    try {
      const body = {
        "user_id": userData.id,
        "user_type": userType,
        "notification_id": notificationId,
      }
      const response = await UseApi.post('/read_notification', body, userToken);
      if (response.code === 200) {
        console.log(">>>>>>>>>>>>>>10", response);
        setNotification(response.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async (requestId, status, notificationId) => {
    console.log("=======/*-/*-/*-", requestId, status)
    const requestData = {
      requestId, status
    }
    readNotification(notificationId);
    navigate('/transactiondetails', { state: requestData });
  }


  const fetchData = async () => {
    try {
      const response = await UseApi.get('/notification_list?user_id=' + userData.id + '&user_type=' + userType, userToken);
      if (response.code === 200) {
        console.log(">>>>>>>>>>>>>>10", response);
        setNotification(response.result);
      } else {
        setListMessage(response.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    document.title = "Notifications"
    fetchData();
    return () => {
      fetchData();
    }
  }, []);

  // console.log("-----/*-/*-/*-/*-/*-/*-/*-/*-/*-/*-/*-/*-", status)
  return (
    <>
      <section id="main-box">
        <div className="container-box">
          <Sidebar />
          <div className="right-sidebar">
            <Header title={"NOTIFICATION"} />
            <div className="main-contant">


              <div className="container-fluid">
                <section className="contant-box" id="notificationSection">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      {notifications != null && (<div className="nav nav-tabs border-0 " id="nav-tab" role="tablist">
                        {notifications.map((notification, index) => (
                          <button className="nav-link w-100 " key={index}>
                            <div className={'payment-card' + (notification.is_read === "0" ? ' uncheck' : '')}
                              onClick={() => handleSubmit(notification.request_id, notification.status, notification.notification_id)}>
                              <a
                                href="#"
                                className="d-flex align-items-center justify-content-between active"
                              >
                                <div className="user-info text-start">
                                  {/* <div className={`user-info text-start`}> */}
                                  <h6>
                                    {notification.notification_title}
                                  </h6>
                                  <span className="cancel">
                                    {notification.notification_message}
                                  </span>
                                </div>
                                {/* <div className="icon-box"> */}

                                {(() => {
                                  if (notification.status == "0") {
                                    return <div className={`icon-box `}>
                                      <span className="px-2">
                                        Accepted
                                      </span>
                                      <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                                    </div>
                                  } else if (notification.status == "1") {

                                    return <div className={`icon-box `}>
                                      <span className="px-2">
                                        Pending
                                      </span>
                                      <FontAwesomeIcon icon={faCircle} style={{ color: 'yellow' }} />
                                    </div>
                                  } else if (notification.status == "2") {

                                    return <div className={`icon-box `}>
                                      <span className="px-2">
                                        Rejected
                                      </span>
                                      <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                                    </div>
                                  }
                                  <div className={`icon-box ${status}`}>
                                    <span>

                                    </span>
                                    <i className="fa-solid fa-circle green" />
                                  </div>
                                })()}
                              </a>
                            </div>
                          </button>
                        ))}
                      </div>
                      )}
                    </div>
                  </div>
                  {listMessage !== "" ?

                    <div className="empty-data-message">
                      <div className="logo-box text-center">
                        <img src={ZLogo} alt="logo img" />
                      </div>
                      <div className="text-box text-center">
                        <span>
                          {listMessage}
                        </span>
                      </div>
                    </div> : null
                  }
                </section>
              </div>
            </div>
          </div>
          <Footer from={"notification"} />
        </div>
      </section>
    </>
  )
}

export default Notification;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import zybraLogo from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import UseApi from "../../Api/useApi";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const { logout } = useContext(AuthContext);
  const [count, setCount] = useState("");

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const userType = JSON.parse(localStorage.getItem("user_type"));
  const conv_id = localStorage.getItem("support_conv_id");

  const onClicklogout = () => {
    Swal.fire({
      title: "Logout",
      text: "Are you sure you wont to Logout?",
      icon: "warning",
      showCancelButton: true, // Add a Cancel button
      confirmButtonColor: "#3085d6", // Optional: Set color for OK button (blue)
      cancelButtonColor: "#d33", // Optional: Set color for Cancel button (red)
    }).then((result) => {
      if (result.isConfirmed) {
        // Check if user clicked OK
        logout();
      }
    });
  };

  const addSupprt = (event) => {
    event.preventDefault();
    const data = {
      state: conv_id,
    };
    navigate("/user/chat", { state: data });
  };

  useEffect(() => {
    const getUnreadCount = async () => {
      const response = await UseApi.get(
        "/unread_count?user_id=" + user_data.id + "&user_type=" + userType,
        token
      );

      // console.log("Payment response ", response);
      if (response.code == 200) {
        setCount(response.result);
      }
    };
    return () => {
      getUnreadCount();
    };
  }, []);

  return (
    <>
      {(() => {
        if (userType == 2) {
          console.log("*************************.............");
          return (
            <div className="left-sidebar">
              <div className="main-navbar">
                <div className="nav">
                  <div className="nav-logo">
                    <Link to="/" className="full-logo">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                    <Link to="/" className="close-btn">
                      <FontAwesomeIcon icon={faXmark} />
                    </Link>
                  </div>
                  <ul className="nav-bar-nav">
                    <li className="nav-item">
                      <Link to="/" className="nav-link demo active ">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_16_367)">
                              <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                              <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                              <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                              <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_16_367">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/chat" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            10
                          </span>
                        </div>
                        <span>Chat</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/payment" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            10
                          </span>
                        </div>
                        <span>Payment</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/notification" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={21}
                            height={26}
                            viewBox="0 0 21 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                          >
                            <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                            <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            1
                          </span>
                        </div>
                        <span>Notification</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={21}
                            height={26}
                            viewBox="0 0 21 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                          >
                            <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                            <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            1
                          </span>
                        </div>
                        <span>User</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/blockuser" className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                          </svg>
                        </div>
                        <span>Block User</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link onClick={onClicklogout} className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_95_1945)">
                              <path d="M18.2553 14.3259C18.0007 14.3049 17.7482 14.3858 17.5531 14.5508C17.358 14.7158 17.2364 14.9514 17.2148 15.2059C17.1295 16.2124 17.0238 17.0879 16.9314 17.548V17.5535C16.6587 18.9602 15.3262 20.3506 13.9597 20.6563C13.8414 20.6806 13.7226 20.704 13.6042 20.7264C13.7058 19.4198 13.7815 17.749 13.7713 16.4607C13.7865 14.4924 13.5981 11.4618 13.4311 10.2558C13.131 8.0351 11.7654 5.57221 10.2547 4.52561L10.2456 4.51952C9.19516 3.8099 8.06943 3.21876 6.88892 2.7569C6.67835 2.67531 6.47032 2.59914 6.26482 2.52838C7.38546 2.35512 8.51799 2.27023 9.65193 2.27448C11.1957 2.27448 12.6049 2.41514 13.9597 2.69647C15.3262 3.00116 16.6587 4.39256 16.9314 5.7992V5.80479C17.0233 6.26182 17.129 7.13729 17.2143 8.14073C17.236 8.39574 17.3581 8.6317 17.5537 8.79669C17.7494 8.96169 18.0026 9.0422 18.2576 9.02051C18.5126 8.99883 18.7486 8.87673 18.9135 8.68107C19.0785 8.48542 19.159 8.23223 19.1374 7.97721C19.0449 6.891 18.9312 5.96221 18.8246 5.42799C18.4036 3.2642 16.4886 1.27917 14.37 0.809946L14.3578 0.807407C12.8689 0.498657 11.3303 0.347329 9.64838 0.344282C7.9665 0.341235 6.43392 0.496626 4.94502 0.807915L4.93232 0.810454C3.98678 1.02018 3.08236 1.53206 2.33283 2.22624C2.13026 2.37898 1.9532 2.56289 1.80826 2.77112C1.14607 3.54401 0.665174 4.46874 0.478299 5.42799C0.244198 6.60155 -0.0203726 9.64487 -6.00609e-05 11.6761C-0.0112319 12.7659 0.059354 14.1471 0.163963 15.3745C0.219823 16.1616 0.283299 16.8472 0.344237 17.2869C0.644354 19.5076 2.00986 21.9705 3.52111 23.0171L3.52974 23.0232C4.5804 23.7327 5.70629 24.3238 6.88689 24.7858C8.08228 25.2499 9.17916 25.5313 10.241 25.646H10.2501C11.6771 25.7735 12.974 24.5349 13.3666 22.7241C13.6987 22.6713 14.0279 22.6112 14.3543 22.5438L14.3665 22.5413C16.4856 22.0721 18.4 20.087 18.821 17.9232C18.9276 17.388 19.0419 16.4572 19.1343 15.3684C19.1558 15.1136 19.0754 14.8607 18.9105 14.6653C18.7457 14.4698 18.51 14.3477 18.2553 14.3259Z" />
                              <path d="M26.0046 11.6746C26.0046 11.6426 26.0046 11.6106 25.9995 11.5786C25.9997 11.5766 25.9997 11.5746 25.9995 11.5725C25.9964 11.5425 25.9919 11.5127 25.9858 11.4832V11.4766C25.9477 11.2932 25.8568 11.125 25.7243 10.9926L22.2711 7.53644C22.0902 7.35543 21.8447 7.25371 21.5888 7.25366C21.3329 7.25361 21.0874 7.35524 20.9064 7.53618C20.7254 7.71713 20.6237 7.96256 20.6236 8.2185C20.6236 8.47444 20.7252 8.71992 20.9061 8.90093L22.7175 10.7118H16.0565C15.8006 10.7118 15.5552 10.8134 15.3743 10.9944C15.1933 11.1753 15.0917 11.4207 15.0917 11.6766C15.0917 11.9325 15.1933 12.1779 15.3743 12.3589C15.5552 12.5398 15.8006 12.6415 16.0565 12.6415H22.7089L20.899 14.4513C20.7187 14.6324 20.6176 14.8776 20.6178 15.1332C20.6181 15.3887 20.7197 15.6337 20.9004 15.8144C21.0811 15.9951 21.3261 16.0967 21.5817 16.097C21.8372 16.0973 22.0824 15.9961 22.2635 15.8158L25.7009 12.3784C25.8256 12.2617 25.9167 12.1137 25.965 11.9498C25.9737 11.9206 25.9808 11.891 25.9863 11.861C25.9865 11.8567 25.9865 11.8525 25.9863 11.8483C25.9909 11.8224 25.9954 11.7975 25.998 11.7701C26.0005 11.7426 25.998 11.7305 25.998 11.7112C25.998 11.7 25.998 11.6888 25.998 11.6776L26.0046 11.6746Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_95_1945">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      })()}

      {(() => {
        if (userType == 3) {
          return (
            <div className="left-sidebar">
              <div className="main-navbar">
                <div className="nav">
                  <div className="nav-logo">
                    <Link to="/dashboard" className="full-logo">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                    <Link to="/dashboard" className="close-btn">
                      <FontAwesomeIcon icon={faXmark} />
                    </Link>
                  </div>
                  <ul className="nav-bar-nav">
                    <li className="nav-item">
                      {/* {userType == 3 ?
                  <Link to="/dashboard" className="nav-link demo active ">
                    <div className="icon-box">
                      <svg
                        width={26}
                        height={26}
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_16_367)">
                          <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                          <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                          <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                          <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_16_367">
                            <rect width={26} height={26} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>Dashboard</span>
                  </Link> :
                  <Link to="/" className="nav-link demo active ">
                    <div className="icon-box">
                      <svg
                        width={26}
                        height={26}
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_16_367)">
                          <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                          <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                          <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                          <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_16_367">
                            <rect width={26} height={26} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>Dashboard</span>
                  </Link>
                } */}
                      <Link to="/dashboard" className="nav-link demo active ">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_16_367)">
                              <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                              <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                              <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                              <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_16_367">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/user/chat"
                        className="nav-link demo"
                        onClick={addSupprt}
                      >
                        <div className="icon-box position-relative">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            {count.chat}
                          </span>
                        </div>
                        <span>Chat</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/payment" className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                          </svg>
                        </div>
                        <span>Payment</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/notification" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={21}
                            height={26}
                            viewBox="0 0 21 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                          >
                            <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                            <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            {count.notification}
                          </span>
                        </div>
                        <span>Notification</span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                <Link href="user" className="nav-link demo">
                  <div className="icon-box">
                    <svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.6798 12.6862C16.183 12.6862 19.0229 9.84628 19.0229 6.34309C19.0229 2.8399 16.183 0 12.6798 0C9.17657 0 6.33667 2.8399 6.33667 6.34309C6.33667 9.84628 9.17657 12.6862 12.6798 12.6862Z" />
                      <path d="M23.9905 20.1243L23.8169 19.6969C23.2335 18.2312 22.2304 16.9703 20.9333 16.0724C19.6363 15.1744 18.103 14.6793 16.5257 14.6492H8.84722C7.26994 14.6793 5.73665 15.1744 4.43959 16.0724C3.14253 16.9703 2.13941 18.2312 1.556 19.6969L1.36904 20.1109C1.07782 20.7793 0.955933 21.5092 1.01417 22.2359C1.0724 22.9627 1.30896 23.6638 1.70289 24.2773C2.02367 24.8012 2.47296 25.2344 3.00815 25.5359C3.54333 25.8373 4.14667 25.9971 4.76093 26H20.5853C21.2013 25.9968 21.8064 25.8371 22.3437 25.5357C22.881 25.2344 23.3328 24.8013 23.6567 24.2773C24.0472 23.665 24.2819 22.9663 24.3401 22.2423C24.3983 21.5183 24.2783 20.7911 23.9905 20.1243Z" />
                    </svg>
                  </div>
                  <span>User</span>
                </Link>
              </li> */}
                    {/* <li className="nav-item">
                <Link href="blockuser" className="nav-link demo">
                  <div className="icon-box">
                    <svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.6798 12.6862C16.183 12.6862 19.0229 9.84628 19.0229 6.34309C19.0229 2.8399 16.183 0 12.6798 0C9.17657 0 6.33667 2.8399 6.33667 6.34309C6.33667 9.84628 9.17657 12.6862 12.6798 12.6862Z" />
                      <path d="M23.9905 20.1243L23.8169 19.6969C23.2335 18.2312 22.2304 16.9703 20.9333 16.0724C19.6363 15.1744 18.103 14.6793 16.5257 14.6492H8.84722C7.26994 14.6793 5.73665 15.1744 4.43959 16.0724C3.14253 16.9703 2.13941 18.2312 1.556 19.6969L1.36904 20.1109C1.07782 20.7793 0.955933 21.5092 1.01417 22.2359C1.0724 22.9627 1.30896 23.6638 1.70289 24.2773C2.02367 24.8012 2.47296 25.2344 3.00815 25.5359C3.54333 25.8373 4.14667 25.9971 4.76093 26H20.5853C21.2013 25.9968 21.8064 25.8371 22.3437 25.5357C22.881 25.2344 23.3328 24.8013 23.6567 24.2773C24.0472 23.665 24.2819 22.9663 24.3401 22.2423C24.3983 21.5183 24.2783 20.7911 23.9905 20.1243Z" />
                      <g clipPath="url(#clip0_95_1950)">
                        <path d="M21 9C19.3431 9 18 10.3431 18 12C18 13.6569 19.3431 15 21 15C22.6569 15 24 13.6569 24 12C24 10.3431 22.6569 9 21 9ZM19.8204 10.2118C20.1704 9.98067 20.5806 9.85736 21 9.85714C21.42 9.8567 21.8308 9.98046 22.1807 10.2129L19.2129 13.1807C18.5608 12.1931 18.8328 10.8639 19.8204 10.2118ZM22.1796 13.7882C21.8296 14.0193 21.4195 14.1426 21 14.1429C20.58 14.1433 20.1692 14.0195 19.8193 13.7871L22.7871 10.8193C23.4392 11.8069 23.1672 13.1361 22.1796 13.7882Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_95_1950">
                          <rect
                            width={6}
                            height={6}
                            fill="white"
                            transform="translate(18 9)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span>Block User</span>
                </Link>
              </li> */}
                    {/* <li className="nav-item">
                <Link href="transaction" className="nav-link demo">
                  <div className="icon-box">
                    <svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.6798 12.6862C16.183 12.6862 19.0229 9.84628 19.0229 6.34309C19.0229 2.8399 16.183 0 12.6798 0C9.17657 0 6.33667 2.8399 6.33667 6.34309C6.33667 9.84628 9.17657 12.6862 12.6798 12.6862Z" />
                      <path d="M23.9905 20.1243L23.8169 19.6969C23.2335 18.2312 22.2304 16.9703 20.9333 16.0724C19.6363 15.1744 18.103 14.6793 16.5257 14.6492H8.84722C7.26994 14.6793 5.73665 15.1744 4.43959 16.0724C3.14253 16.9703 2.13941 18.2312 1.556 19.6969L1.36904 20.1109C1.07782 20.7793 0.955933 21.5092 1.01417 22.2359C1.0724 22.9627 1.30896 23.6638 1.70289 24.2773C2.02367 24.8012 2.47296 25.2344 3.00815 25.5359C3.54333 25.8373 4.14667 25.9971 4.76093 26H20.5853C21.2013 25.9968 21.8064 25.8371 22.3437 25.5357C22.881 25.2344 23.3328 24.8013 23.6567 24.2773C24.0472 23.665 24.2819 22.9663 24.3401 22.2423C24.3983 21.5183 24.2783 20.7911 23.9905 20.1243Z" />
                      <g clipPath="url(#clip0_95_1950)">
                        <path d="M21 9C19.3431 9 18 10.3431 18 12C18 13.6569 19.3431 15 21 15C22.6569 15 24 13.6569 24 12C24 10.3431 22.6569 9 21 9ZM19.8204 10.2118C20.1704 9.98067 20.5806 9.85736 21 9.85714C21.42 9.8567 21.8308 9.98046 22.1807 10.2129L19.2129 13.1807C18.5608 12.1931 18.8328 10.8639 19.8204 10.2118ZM22.1796 13.7882C21.8296 14.0193 21.4195 14.1426 21 14.1429C20.58 14.1433 20.1692 14.0195 19.8193 13.7871L22.7871 10.8193C23.4392 11.8069 23.1672 13.1361 22.1796 13.7882Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_95_1950">
                          <rect
                            width={6}
                            height={6}
                            fill="white"
                            transform="translate(18 9)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span>Transaction List</span>
                </Link>
              </li> */}
                    <li className="nav-item">
                      <Link onClick={onClicklogout} className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_95_1945)">
                              <path d="M18.2553 14.3259C18.0007 14.3049 17.7482 14.3858 17.5531 14.5508C17.358 14.7158 17.2364 14.9514 17.2148 15.2059C17.1295 16.2124 17.0238 17.0879 16.9314 17.548V17.5535C16.6587 18.9602 15.3262 20.3506 13.9597 20.6563C13.8414 20.6806 13.7226 20.704 13.6042 20.7264C13.7058 19.4198 13.7815 17.749 13.7713 16.4607C13.7865 14.4924 13.5981 11.4618 13.4311 10.2558C13.131 8.0351 11.7654 5.57221 10.2547 4.52561L10.2456 4.51952C9.19516 3.8099 8.06943 3.21876 6.88892 2.7569C6.67835 2.67531 6.47032 2.59914 6.26482 2.52838C7.38546 2.35512 8.51799 2.27023 9.65193 2.27448C11.1957 2.27448 12.6049 2.41514 13.9597 2.69647C15.3262 3.00116 16.6587 4.39256 16.9314 5.7992V5.80479C17.0233 6.26182 17.129 7.13729 17.2143 8.14073C17.236 8.39574 17.3581 8.6317 17.5537 8.79669C17.7494 8.96169 18.0026 9.0422 18.2576 9.02051C18.5126 8.99883 18.7486 8.87673 18.9135 8.68107C19.0785 8.48542 19.159 8.23223 19.1374 7.97721C19.0449 6.891 18.9312 5.96221 18.8246 5.42799C18.4036 3.2642 16.4886 1.27917 14.37 0.809946L14.3578 0.807407C12.8689 0.498657 11.3303 0.347329 9.64838 0.344282C7.9665 0.341235 6.43392 0.496626 4.94502 0.807915L4.93232 0.810454C3.98678 1.02018 3.08236 1.53206 2.33283 2.22624C2.13026 2.37898 1.9532 2.56289 1.80826 2.77112C1.14607 3.54401 0.665174 4.46874 0.478299 5.42799C0.244198 6.60155 -0.0203726 9.64487 -6.00609e-05 11.6761C-0.0112319 12.7659 0.059354 14.1471 0.163963 15.3745C0.219823 16.1616 0.283299 16.8472 0.344237 17.2869C0.644354 19.5076 2.00986 21.9705 3.52111 23.0171L3.52974 23.0232C4.5804 23.7327 5.70629 24.3238 6.88689 24.7858C8.08228 25.2499 9.17916 25.5313 10.241 25.646H10.2501C11.6771 25.7735 12.974 24.5349 13.3666 22.7241C13.6987 22.6713 14.0279 22.6112 14.3543 22.5438L14.3665 22.5413C16.4856 22.0721 18.4 20.087 18.821 17.9232C18.9276 17.388 19.0419 16.4572 19.1343 15.3684C19.1558 15.1136 19.0754 14.8607 18.9105 14.6653C18.7457 14.4698 18.51 14.3477 18.2553 14.3259Z" />
                              <path d="M26.0046 11.6746C26.0046 11.6426 26.0046 11.6106 25.9995 11.5786C25.9997 11.5766 25.9997 11.5746 25.9995 11.5725C25.9964 11.5425 25.9919 11.5127 25.9858 11.4832V11.4766C25.9477 11.2932 25.8568 11.125 25.7243 10.9926L22.2711 7.53644C22.0902 7.35543 21.8447 7.25371 21.5888 7.25366C21.3329 7.25361 21.0874 7.35524 20.9064 7.53618C20.7254 7.71713 20.6237 7.96256 20.6236 8.2185C20.6236 8.47444 20.7252 8.71992 20.9061 8.90093L22.7175 10.7118H16.0565C15.8006 10.7118 15.5552 10.8134 15.3743 10.9944C15.1933 11.1753 15.0917 11.4207 15.0917 11.6766C15.0917 11.9325 15.1933 12.1779 15.3743 12.3589C15.5552 12.5398 15.8006 12.6415 16.0565 12.6415H22.7089L20.899 14.4513C20.7187 14.6324 20.6176 14.8776 20.6178 15.1332C20.6181 15.3887 20.7197 15.6337 20.9004 15.8144C21.0811 15.9951 21.3261 16.0967 21.5817 16.097C21.8372 16.0973 22.0824 15.9961 22.2635 15.8158L25.7009 12.3784C25.8256 12.2617 25.9167 12.1137 25.965 11.9498C25.9737 11.9206 25.9808 11.891 25.9863 11.861C25.9865 11.8567 25.9865 11.8525 25.9863 11.8483C25.9909 11.8224 25.9954 11.7975 25.998 11.7701C26.0005 11.7426 25.998 11.7305 25.998 11.7112C25.998 11.7 25.998 11.6888 25.998 11.6776L26.0046 11.6746Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_95_1945">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      })()}

      {(() => {
        if (userType == 4) {
          return (
            <div className="left-sidebar">
              <div className="main-navbar">
                <div className="nav">
                  <div className="nav-logo">
                    <Link to="/" className="full-logo">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                    <Link to="/" className="close-btn">
                      <FontAwesomeIcon icon={faXmark} />
                    </Link>
                  </div>
                  <ul className="nav-bar-nav">
                    <li className="nav-item">
                      <Link to="/" className="nav-link demo active ">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_16_367)">
                              <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                              <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                              <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                              <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_16_367">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/chat" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            10
                          </span>
                        </div>
                        <span>Chat</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/notification" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={21}
                            height={26}
                            viewBox="0 0 21 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                          >
                            <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                            <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            1
                          </span>
                        </div>
                        <span>Notification</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/creategroup" className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                          </svg>
                        </div>
                        <span>Create Group</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/blockuser" className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3.50693 12.8386V17.8525C3.50693 18.8928 4.34675 19.7327 5.38713 19.7327C6.42751 19.7327 7.26733 18.8928 7.26733 17.8525V12.8386C7.26733 11.7982 6.42751 10.9584 5.38713 10.9584C4.34675 10.9584 3.50693 11.7982 3.50693 12.8386ZM11.0277 12.8386V17.8525C11.0277 18.8928 11.8675 19.7327 12.9079 19.7327C13.9483 19.7327 14.7881 18.8928 14.7881 17.8525V12.8386C14.7881 11.7982 13.9483 10.9584 12.9079 10.9584C11.8675 10.9584 11.0277 11.7982 11.0277 12.8386ZM2.8802 26H22.9356C23.976 26 24.8158 25.1602 24.8158 24.1198C24.8158 23.0794 23.976 22.2396 22.9356 22.2396H2.8802C1.83982 22.2396 1 23.0794 1 24.1198C1 25.1602 1.83982 26 2.8802 26ZM18.5485 12.8386V17.8525C18.5485 18.8928 19.3883 19.7327 20.4287 19.7327C21.4691 19.7327 22.3089 18.8928 22.3089 17.8525V12.8386C22.3089 11.7982 21.4691 10.9584 20.4287 10.9584C19.3883 10.9584 18.5485 11.7982 18.5485 12.8386ZM11.7422 0.291431L1.83982 5.50585C1.3259 5.76908 1 6.30807 1 6.88466C1 7.74955 1.70194 8.45149 2.56683 8.45149H23.2616C24.1139 8.45149 24.8158 7.74955 24.8158 6.88466C24.8158 6.30807 24.4899 5.76908 23.976 5.50585L14.0736 0.291431C13.3466 -0.0971436 12.4692 -0.0971436 11.7422 0.291431Z" />
                          </svg>
                        </div>
                        <span>Block User</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link onClick={onClicklogout} className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_95_1945)">
                              <path d="M18.2553 14.3259C18.0007 14.3049 17.7482 14.3858 17.5531 14.5508C17.358 14.7158 17.2364 14.9514 17.2148 15.2059C17.1295 16.2124 17.0238 17.0879 16.9314 17.548V17.5535C16.6587 18.9602 15.3262 20.3506 13.9597 20.6563C13.8414 20.6806 13.7226 20.704 13.6042 20.7264C13.7058 19.4198 13.7815 17.749 13.7713 16.4607C13.7865 14.4924 13.5981 11.4618 13.4311 10.2558C13.131 8.0351 11.7654 5.57221 10.2547 4.52561L10.2456 4.51952C9.19516 3.8099 8.06943 3.21876 6.88892 2.7569C6.67835 2.67531 6.47032 2.59914 6.26482 2.52838C7.38546 2.35512 8.51799 2.27023 9.65193 2.27448C11.1957 2.27448 12.6049 2.41514 13.9597 2.69647C15.3262 3.00116 16.6587 4.39256 16.9314 5.7992V5.80479C17.0233 6.26182 17.129 7.13729 17.2143 8.14073C17.236 8.39574 17.3581 8.6317 17.5537 8.79669C17.7494 8.96169 18.0026 9.0422 18.2576 9.02051C18.5126 8.99883 18.7486 8.87673 18.9135 8.68107C19.0785 8.48542 19.159 8.23223 19.1374 7.97721C19.0449 6.891 18.9312 5.96221 18.8246 5.42799C18.4036 3.2642 16.4886 1.27917 14.37 0.809946L14.3578 0.807407C12.8689 0.498657 11.3303 0.347329 9.64838 0.344282C7.9665 0.341235 6.43392 0.496626 4.94502 0.807915L4.93232 0.810454C3.98678 1.02018 3.08236 1.53206 2.33283 2.22624C2.13026 2.37898 1.9532 2.56289 1.80826 2.77112C1.14607 3.54401 0.665174 4.46874 0.478299 5.42799C0.244198 6.60155 -0.0203726 9.64487 -6.00609e-05 11.6761C-0.0112319 12.7659 0.059354 14.1471 0.163963 15.3745C0.219823 16.1616 0.283299 16.8472 0.344237 17.2869C0.644354 19.5076 2.00986 21.9705 3.52111 23.0171L3.52974 23.0232C4.5804 23.7327 5.70629 24.3238 6.88689 24.7858C8.08228 25.2499 9.17916 25.5313 10.241 25.646H10.2501C11.6771 25.7735 12.974 24.5349 13.3666 22.7241C13.6987 22.6713 14.0279 22.6112 14.3543 22.5438L14.3665 22.5413C16.4856 22.0721 18.4 20.087 18.821 17.9232C18.9276 17.388 19.0419 16.4572 19.1343 15.3684C19.1558 15.1136 19.0754 14.8607 18.9105 14.6653C18.7457 14.4698 18.51 14.3477 18.2553 14.3259Z" />
                              <path d="M26.0046 11.6746C26.0046 11.6426 26.0046 11.6106 25.9995 11.5786C25.9997 11.5766 25.9997 11.5746 25.9995 11.5725C25.9964 11.5425 25.9919 11.5127 25.9858 11.4832V11.4766C25.9477 11.2932 25.8568 11.125 25.7243 10.9926L22.2711 7.53644C22.0902 7.35543 21.8447 7.25371 21.5888 7.25366C21.3329 7.25361 21.0874 7.35524 20.9064 7.53618C20.7254 7.71713 20.6237 7.96256 20.6236 8.2185C20.6236 8.47444 20.7252 8.71992 20.9061 8.90093L22.7175 10.7118H16.0565C15.8006 10.7118 15.5552 10.8134 15.3743 10.9944C15.1933 11.1753 15.0917 11.4207 15.0917 11.6766C15.0917 11.9325 15.1933 12.1779 15.3743 12.3589C15.5552 12.5398 15.8006 12.6415 16.0565 12.6415H22.7089L20.899 14.4513C20.7187 14.6324 20.6176 14.8776 20.6178 15.1332C20.6181 15.3887 20.7197 15.6337 20.9004 15.8144C21.0811 15.9951 21.3261 16.0967 21.5817 16.097C21.8372 16.0973 22.0824 15.9961 22.2635 15.8158L25.7009 12.3784C25.8256 12.2617 25.9167 12.1137 25.965 11.9498C25.9737 11.9206 25.9808 11.891 25.9863 11.861C25.9865 11.8567 25.9865 11.8525 25.9863 11.8483C25.9909 11.8224 25.9954 11.7975 25.998 11.7701C26.0005 11.7426 25.998 11.7305 25.998 11.7112C25.998 11.7 25.998 11.6888 25.998 11.6776L26.0046 11.6746Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_95_1945">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      })()}

      {(() => {
        if (userType == 5 || userType == 6) {
          return (
            <div className="left-sidebar">
              <div className="main-navbar">
                <div className="nav">
                  <div className="nav-logo">
                    <Link to="/" className="full-logo">
                      <img src={zybraLogo} alt="logo" />
                    </Link>
                    <Link to="/" className="close-btn">
                      <FontAwesomeIcon icon={faXmark} />
                    </Link>
                  </div>
                  <ul className="nav-bar-nav">
                    <li className="nav-item">
                      <Link to="/" className="nav-link demo active ">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_16_367)">
                              <path d="M10.0208 0H1.89577C0.850388 0 0 0.850388 0 1.89577V6.77077C0 7.81635 0.850388 8.66673 1.89577 8.66673H10.0208C11.0663 8.66673 11.9167 7.81635 11.9167 6.77077V1.89577C11.9167 0.850388 11.0663 0 10.0208 0Z" />
                              <path d="M10.0208 10.8333H1.89577C0.850388 10.8333 0 11.6836 0 12.7292V24.1042C0 25.1496 0.850388 26 1.89577 26H10.0208C11.0663 26 11.9167 25.1496 11.9167 24.1042V12.7292C11.9167 11.6836 11.0663 10.8333 10.0208 10.8333Z" />
                              <path d="M24.1042 17.3333H15.9792C14.9336 17.3333 14.0833 18.1836 14.0833 19.2292V24.1042C14.0833 25.1496 14.9336 26 15.9792 26H24.1042C25.1496 26 26 25.1496 26 24.1042V19.2292C26 18.1836 25.1496 17.3333 24.1042 17.3333Z" />
                              <path d="M24.1042 0H15.9792C14.9336 0 14.0833 0.850388 14.0833 1.89577V13.2708C14.0833 14.3163 14.9336 15.1667 15.9792 15.1667H24.1042C25.1496 15.1667 26 14.3163 26 13.2708V1.89577C26 0.850388 25.1496 0 24.1042 0Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_16_367">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/chat" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.806 0.764893H7.19399C6.8035 0.764893 6.42804 0.779238 6.06758 0.822276C2.02753 1.15223 0 3.43322 0 7.63656V13.3749C0 19.1132 2.403 20.2466 7.19399 20.2466H7.79474C8.12516 20.2466 8.5607 20.4618 8.75595 20.7056L10.5582 23.001C11.3542 24.0195 12.6458 24.0195 13.4418 23.001L15.2441 20.7056C15.4693 20.4187 15.8298 20.2466 16.2053 20.2466H16.806C21.2065 20.2466 23.5945 18.3242 23.9399 14.4508C23.985 14.1065 24 13.7479 24 13.3749V7.63656C24 3.06023 21.597 0.764893 16.806 0.764893ZM6.75845 12.2416C5.9174 12.2416 5.25657 11.596 5.25657 10.807C5.25657 10.018 5.93242 9.37241 6.75845 9.37241C7.58448 9.37241 8.26033 10.018 8.26033 10.807C8.26033 11.596 7.58448 12.2416 6.75845 12.2416ZM12 12.2416C11.1589 12.2416 10.4981 11.596 10.4981 10.807C10.4981 10.018 11.174 9.37241 12 9.37241C12.826 9.37241 13.5019 10.018 13.5019 10.807C13.5019 11.596 12.8411 12.2416 12 12.2416ZM17.2566 12.2416C16.4155 12.2416 15.7547 11.596 15.7547 10.807C15.7547 10.018 16.4305 9.37241 17.2566 9.37241C18.0826 9.37241 18.7584 10.018 18.7584 10.807C18.7584 11.596 18.0826 12.2416 17.2566 12.2416Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            10
                          </span>
                        </div>
                        <span>Chat</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/notification" className="nav-link demo">
                        <div className="icon-box position-relative">
                          <svg
                            width={21}
                            height={26}
                            viewBox="0 0 21 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                          >
                            <path d="M14.3 22.1001C14.3 23.577 13.5566 24.7074 12.3493 25.4463C11.1424 26.1847 9.65531 26.1847 8.45066 25.4463C7.243 24.7074 6.5 23.577 6.5 22.1001" />
                            <path d="M10.3997 0C11.3821 0 12.1734 0.604283 12.1734 1.53355C12.1734 2.11845 12.1939 2.55488 12.5623 2.66667C15.6952 3.61047 17.7042 5.82271 17.7042 8.69565V13.5244C17.7042 15.7366 18.6113 16.1845 19.753 17.0307C21.4064 18.2541 21.0289 20.8017 19.1317 20.8H1.6683C-0.228946 20.8017 -0.606382 18.2541 1.04699 17.0307C2.18728 16.1845 3.09583 15.7366 3.09583 13.5244V8.69565C3.09583 5.82271 5.10478 3.61047 8.23772 2.66667C8.60509 2.55488 8.6266 2.11845 8.6266 1.53355C8.62695 0.604283 9.4186 0 10.3997 0Z" />
                          </svg>
                          <span className=" badge border border-dark   bg-danger rounded-circle">
                            1
                          </span>
                        </div>
                        <span>Notification</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link onClick={onClicklogout} className="nav-link demo">
                        <div className="icon-box">
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_95_1945)">
                              <path d="M18.2553 14.3259C18.0007 14.3049 17.7482 14.3858 17.5531 14.5508C17.358 14.7158 17.2364 14.9514 17.2148 15.2059C17.1295 16.2124 17.0238 17.0879 16.9314 17.548V17.5535C16.6587 18.9602 15.3262 20.3506 13.9597 20.6563C13.8414 20.6806 13.7226 20.704 13.6042 20.7264C13.7058 19.4198 13.7815 17.749 13.7713 16.4607C13.7865 14.4924 13.5981 11.4618 13.4311 10.2558C13.131 8.0351 11.7654 5.57221 10.2547 4.52561L10.2456 4.51952C9.19516 3.8099 8.06943 3.21876 6.88892 2.7569C6.67835 2.67531 6.47032 2.59914 6.26482 2.52838C7.38546 2.35512 8.51799 2.27023 9.65193 2.27448C11.1957 2.27448 12.6049 2.41514 13.9597 2.69647C15.3262 3.00116 16.6587 4.39256 16.9314 5.7992V5.80479C17.0233 6.26182 17.129 7.13729 17.2143 8.14073C17.236 8.39574 17.3581 8.6317 17.5537 8.79669C17.7494 8.96169 18.0026 9.0422 18.2576 9.02051C18.5126 8.99883 18.7486 8.87673 18.9135 8.68107C19.0785 8.48542 19.159 8.23223 19.1374 7.97721C19.0449 6.891 18.9312 5.96221 18.8246 5.42799C18.4036 3.2642 16.4886 1.27917 14.37 0.809946L14.3578 0.807407C12.8689 0.498657 11.3303 0.347329 9.64838 0.344282C7.9665 0.341235 6.43392 0.496626 4.94502 0.807915L4.93232 0.810454C3.98678 1.02018 3.08236 1.53206 2.33283 2.22624C2.13026 2.37898 1.9532 2.56289 1.80826 2.77112C1.14607 3.54401 0.665174 4.46874 0.478299 5.42799C0.244198 6.60155 -0.0203726 9.64487 -6.00609e-05 11.6761C-0.0112319 12.7659 0.059354 14.1471 0.163963 15.3745C0.219823 16.1616 0.283299 16.8472 0.344237 17.2869C0.644354 19.5076 2.00986 21.9705 3.52111 23.0171L3.52974 23.0232C4.5804 23.7327 5.70629 24.3238 6.88689 24.7858C8.08228 25.2499 9.17916 25.5313 10.241 25.646H10.2501C11.6771 25.7735 12.974 24.5349 13.3666 22.7241C13.6987 22.6713 14.0279 22.6112 14.3543 22.5438L14.3665 22.5413C16.4856 22.0721 18.4 20.087 18.821 17.9232C18.9276 17.388 19.0419 16.4572 19.1343 15.3684C19.1558 15.1136 19.0754 14.8607 18.9105 14.6653C18.7457 14.4698 18.51 14.3477 18.2553 14.3259Z" />
                              <path d="M26.0046 11.6746C26.0046 11.6426 26.0046 11.6106 25.9995 11.5786C25.9997 11.5766 25.9997 11.5746 25.9995 11.5725C25.9964 11.5425 25.9919 11.5127 25.9858 11.4832V11.4766C25.9477 11.2932 25.8568 11.125 25.7243 10.9926L22.2711 7.53644C22.0902 7.35543 21.8447 7.25371 21.5888 7.25366C21.3329 7.25361 21.0874 7.35524 20.9064 7.53618C20.7254 7.71713 20.6237 7.96256 20.6236 8.2185C20.6236 8.47444 20.7252 8.71992 20.9061 8.90093L22.7175 10.7118H16.0565C15.8006 10.7118 15.5552 10.8134 15.3743 10.9944C15.1933 11.1753 15.0917 11.4207 15.0917 11.6766C15.0917 11.9325 15.1933 12.1779 15.3743 12.3589C15.5552 12.5398 15.8006 12.6415 16.0565 12.6415H22.7089L20.899 14.4513C20.7187 14.6324 20.6176 14.8776 20.6178 15.1332C20.6181 15.3887 20.7197 15.6337 20.9004 15.8144C21.0811 15.9951 21.3261 16.0967 21.5817 16.097C21.8372 16.0973 22.0824 15.9961 22.2635 15.8158L25.7009 12.3784C25.8256 12.2617 25.9167 12.1137 25.965 11.9498C25.9737 11.9206 25.9808 11.891 25.9863 11.861C25.9865 11.8567 25.9865 11.8525 25.9863 11.8483C25.9909 11.8224 25.9954 11.7975 25.998 11.7701C26.0005 11.7426 25.998 11.7305 25.998 11.7112C25.998 11.7 25.998 11.6888 25.998 11.6776L26.0046 11.6746Z" />
                            </g>
                            <defs>
                              <clipPath id="clip0_95_1945">
                                <rect width={26} height={26} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <span>Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      })()}
    </>
  );
}
export default Sidebar;
